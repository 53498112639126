import { useEffect, useState } from 'react';
import ReactFlow, { Background, Edge, Node, ReactFlowProvider, useReactFlow } from 'reactflow';
import { Link, useParams } from 'react-router-dom';
import { CircularProgress } from '@mui/material';

import { Layout } from 'src/layout/layout';
import { generateEdges } from 'src/pages/wbs/generate-edges';
import { createTreeLayout } from 'src/pages/wbs/create-tree-layout';
import { useGetWbsById } from 'src/hooks/use-get-wbs-by-id';
import { EmptyResult } from 'src/components/empty-result/empty-result';
import { locations } from 'src/constants/routes';
import { ZoomControls } from 'src/components/zoom-controls/zoom-controls';

import { Wbs } from '../../api/okr/wbs/wbs.types';

import { CustomNode } from './okr-tree-custom-node/okr-tree-custom-node';
import styles from './wbs.module.css';
import 'reactflow/dist/style.css';

const NODE_TYPES = { custom: CustomNode };
const HEADING = 'WBS';

function removeChildrenNodes(objectives: Wbs[], hiddenParentIds: string[]): Array<Wbs> {
    return objectives?.map((objective) => ({
        ...objective,
        children:
            hiddenParentIds.indexOf(objective.id) > -1
                ? []
                : removeChildrenNodes(objective.children, hiddenParentIds),
        hasChildren: objective.children.length > 0,
    }));
}

let hiddenParentNodeIds: string[] = [];
let rootParentNodeId: string = '';
let childParentNodeIds: string[] = [];

const WbsPage = () => {
    const { zoomIn, zoomOut } = useReactFlow();
    const [nodes, setNodes] = useState<Node[]>([]);
    const [edges, setEdges] = useState<Edge[]>([]);
    const params = useParams();

    const { data, isLoading, isRefetching, isSuccess } = useGetWbsById(params?.id);

    const treeData = data || [];

    function setData() {
        setNodes(
            createTreeLayout(
                removeChildrenNodes(treeData, hiddenParentNodeIds),
                undefined,
                null,
                // eslint-disable-next-line @typescript-eslint/no-use-before-define
                onClickArrow
            )
        );
        setEdges(generateEdges(removeChildrenNodes(treeData, hiddenParentNodeIds)));
    }

    const onClickArrow = (id: string) => {
        const nodeIdIndex = hiddenParentNodeIds.indexOf(id);

        if (id !== rootParentNodeId) {
            if (hiddenParentNodeIds.length === childParentNodeIds.length) {
                hiddenParentNodeIds = childParentNodeIds.filter(
                    (prevHiddenParentNodeId) => prevHiddenParentNodeId !== id
                );

                setData();

                return;
            }

            if (nodeIdIndex > -1) {
                hiddenParentNodeIds = childParentNodeIds.filter(
                    (prevHiddenParentNodeId) => prevHiddenParentNodeId !== id
                );
            } else {
                hiddenParentNodeIds = [...hiddenParentNodeIds, id];
            }

            setData();

            return;
        }

        if (nodeIdIndex === -1) {
            hiddenParentNodeIds = [...hiddenParentNodeIds, id];
        } else {
            hiddenParentNodeIds = hiddenParentNodeIds.filter(
                (prevHiddenParentNodeId) => prevHiddenParentNodeId !== id
            );
        }

        setData();
    };

    const onClickZoomIn = () => {
        zoomIn();
    };

    const onClickZoomOut = () => {
        zoomOut();
    };

    useEffect(() => {
        if (!isSuccess) return;

        if (treeData[0]) {
            rootParentNodeId = data[0].id;
            hiddenParentNodeIds = [...data[0].children.map((childData) => childData.id)];
            childParentNodeIds = [...hiddenParentNodeIds];

            setData();
        }
    }, [treeData, isSuccess]);

    return (
        <Layout
            headerProps={{
                title: 'Древо целей',
                showTabs: true,
                showNotifications: true,
                showActions: true,
            }}
        >
            {(isLoading || isRefetching) && (
                <div className={styles['spinner-container']}>
                    <CircularProgress size={100} />
                </div>
            )}
            {isSuccess && data.length === 0 && (
                <EmptyResult
                    title={HEADING}
                    action={{
                        component: Link,
                        to: locations.createOKR,
                        children: `Создать ${HEADING}`,
                    }}
                />
            )}

            <div style={{ width: '100%', height: '100%', position: 'relative' }}>
                <ReactFlow nodes={nodes} edges={edges} nodeTypes={NODE_TYPES} fitView minZoom={0.1}>
                    <Background color="#F8FAFC" className={styles.background} />
                    <ZoomControls onClickZoomOut={onClickZoomOut} onClickZoomIn={onClickZoomIn} />
                </ReactFlow>
            </div>
        </Layout>
    );
};
export const WbsTreePage = () => (
    <ReactFlowProvider>
        <WbsPage />
    </ReactFlowProvider>
);
