import { RadioChangeEvent, Radio } from 'antd';
import { useSearchParams } from 'react-router-dom';

import { useGetObjectiveHierarchy } from 'src/hooks/use-get-objective-hierarchy';

import styles from './sort-panel.module.css';

export const SortPanel = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const defaultValue = searchParams.get('sort') ?? '0';

    const { refetch } = useGetObjectiveHierarchy();

    const onChange = (e: RadioChangeEvent) => {
        setSearchParams((params) => {
            params.set('sort', e.target.value);
            return params;
        });
        refetch();
    };

    return (
        <Radio.Group
            onChange={onChange}
            defaultValue={defaultValue}
            className={styles['sort-panel']}
        >
            <Radio.Button value="0">ССП</Radio.Button>
            <Radio.Button value="1">OKR</Radio.Button>
            <Radio.Button value="2">ЖЦП</Radio.Button>
        </Radio.Group>
    );
};
