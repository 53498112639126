import { useMutation, UseMutationOptions } from '@tanstack/react-query';

import { putKeyResult } from 'src/api/okr/key-result/key-result.requests';
import { KeyResult, PutKeyResultPayload } from 'src/api/okr/key-result/key-result.types';

export const usePutKeyResult = (
    config: UseMutationOptions<KeyResult, unknown, PutKeyResultPayload> = {}
) =>
    useMutation<KeyResult, unknown, PutKeyResultPayload>({
        mutationFn: (data) => putKeyResult(data),
        ...config,
    });
