import { FC } from 'react';
import { Typography } from '@mui/material';
import { Progress } from 'antd';

import styles from './progress-bar.module.css';

interface Props {
    title?: string;
    progress: number;
}

export const ProgressBar: FC<Props> = ({ progress, title }) => (
    <div className={styles.block}>
        {title && <Typography className={styles.title}>{title}</Typography>}

        <div className={styles.info}>
            <Progress
                percent={Math.abs(progress)}
                format={(percent) =>
                    progress >= 0 || Number.isNaN(progress) ? `${percent}%` : `-${percent}%`
                }
                size="small"
                strokeColor={progress >= 0 ? '#6323ED' : '#ff4d4f'}
            />
        </div>
    </div>
);
