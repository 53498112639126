import { FC } from 'react';
import { Typography } from '@mui/material';
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';

import { progressUnitsValues } from 'src/constants/progress-units-values';

import styles from './progress-value.module.css';

type Props = {
    progressStartValue?: number;
    progressEndValue?: number;
    progressUnit?: number;
};

export const ProgressValue: FC<Props> = ({
    progressEndValue,
    progressStartValue,
    progressUnit,
}) => {
    const unit = progressUnitsValues.find((value) => value.id === progressUnit);

    return (
        unit && (
            <Typography className={styles['progress-values']}>
                с
                <span className={styles.line}>
                    {progressStartValue}
                    {unit.shortName || <unit.icon fill="#A1A6AF" width={15} height={15} />}
                </span>
                <TrendingFlatIcon /> дo
                <span className={styles.line}>
                    {progressEndValue}
                    {unit.shortName || <unit.icon fill="#A1A6AF" width={15} height={15} />}
                </span>
            </Typography>
        )
    );
};
