import { useQuery } from '@tanstack/react-query';

import { getWbsById } from 'src/api/okr/wbs/wbs.request';
import { Wbs } from 'src/api/okr/wbs/wbs.types';

export const useGetWbsById = (wbsId?: string) =>
    useQuery<Wbs[]>({
        queryKey: [getWbsById.name, wbsId],
        queryFn: () => getWbsById(wbsId),
        refetchOnMount: true,
        refetchOnWindowFocus: true,
        enabled: Boolean(wbsId),
    });
