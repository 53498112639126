import { FC } from 'react';
import {
    Typography,
    Switch,
    FormControlLabel,
    styled,
    Theme,
    CSSObject,
    Box,
    Avatar,
} from '@mui/material';
import TranslateIcon from '@mui/icons-material/Translate';
import { withStyles } from '@mui/styles';
import { BellOutlined, QuestionCircleOutlined, SearchOutlined } from '@ant-design/icons';

// eslint-disable-next-line import/no-cycle
import { useRootLayoutContext } from '../layout';

import styles from './header.module.css';

const headerHeight = 500;

const openedMixin = (theme: Theme): CSSObject => ({
    maxHeight: headerHeight,
    transition: theme.transitions.create('maxHeight', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
});

const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('maxHeight', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
});

const StyledHeader = styled('header')<{ hidden: boolean }>(({ hidden, theme }) => ({
    ...(!hidden && {
        ...openedMixin(theme),
    }),
    ...(hidden && {
        ...closedMixin(theme),
    }),
}));

const IOSStyledSwitch = withStyles({
    root: {
        width: 42,
        height: 26,
        padding: 0,
        margin: 8,
    },
    switchBase: {
        padding: 2,
        '&$checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + $track': {
                backgroundColor: '#6323ED',
                opacity: 1,
                border: 'none',
            },
        },
        '&$focusVisible $thumb': {
            color: '#6323ED',
            border: '6px solid #fff',
        },
    },
    thumb: {
        width: 22,
        height: 22,
    },
    track: {
        borderRadius: 26 / 2,
        border: `1px solid #d9d9d9`,
        backgroundColor: '#f2f2f2',
        opacity: 1,
        transition: 'background-color 0.3s',
    },
    checked: {},
    focusVisible: {},
})(Switch);

interface RootLayoutControlsBoxProps {
    fixed: boolean;
}

const RootLayoutControlsBox = styled('div')<RootLayoutControlsBoxProps>(() => ({
    width: 265,
    height: 45,
    display: 'inline-flex',
    alignItems: 'center',
    backgroundColor: 'transparent',
}));

interface Props {
    title?: string;
}

export const Header: FC<Props> = ({ title }) => {
    const { controlsHidden, showControls, hideControls } = useRootLayoutContext();

    function onChangeRootLayoutControls() {
        return controlsHidden ? showControls() : hideControls();
    }

    return (
        <StyledHeader
            className={`${styles.root} ${title && styles.withContent}`}
            hidden={controlsHidden}
        >
            <div className={styles.container}>
                <div className={styles['heading-block']}>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            width: '100%',
                        }}
                    >
                        <div
                            style={{
                                width: 265,
                                height: 45,
                                display: 'inline-flex',
                            }}
                        >
                            <RootLayoutControlsBox fixed={controlsHidden}>
                                <FormControlLabel
                                    label={
                                        <Typography style={{ fontSize: 14 }}>
                                            Скрыть панели управления
                                        </Typography>
                                    }
                                    labelPlacement="start"
                                    control={
                                        <IOSStyledSwitch
                                            checked={controlsHidden}
                                            onChange={() => onChangeRootLayoutControls()}
                                        />
                                    }
                                />
                            </RootLayoutControlsBox>
                        </div>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                mr: 2,
                                gap: '24px',
                                color: 'rgba(0, 0, 0, 0.85)',
                            }}
                        >
                            <SearchOutlined width={14} height={14} color="rgba(0, 0, 0, 0.85)" />
                            <QuestionCircleOutlined
                                width={14}
                                height={14}
                                color="rgba(0, 0, 0, 0.85)"
                            />
                            <BellOutlined width={14} height={14} color="rgba(0, 0, 0, 0.85)" />
                            <Box sx={{ display: 'flex', gap: '12px', fontSize: '14px' }}>
                                <Avatar sx={{ width: 24, height: 24 }} />
                                <span>Аширбеков Айбек Казбекович</span>
                            </Box>
                            <TranslateIcon
                                sx={{ fontSize: '14px' }}
                                htmlColor="rgba(0, 0, 0, 0.85)"
                            />
                        </Box>
                    </div>
                </div>
            </div>
        </StyledHeader>
    );
};
