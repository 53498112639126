import { FC, ReactNode } from 'react';
import { Typography } from '@mui/material';
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';

import { ProgressBar } from 'src/components/progress-bar/progress-bar';
import { getProgress } from 'src/utils/get-progress';
import { formatDate } from 'src/utils/format-date';
import { Objective } from 'src/api/okr/objective/objective.types';
import { KeyResult } from 'src/api/okr/key-result/key-result.types';

import { ProgressValue } from '../progress-value/progress-value';

import styles from './subtasks-list-item.module.css';

interface Props {
    result: KeyResult | Objective;
    editIcon?: ReactNode;
}

export const SubtasksListItem: FC<Props> = ({ result, editIcon }) => (
    <div className={styles['key-result']}>
        <div className={styles['heading-block']}>
            <Typography className={styles.heading}>{result.title}</Typography>

            {editIcon}
        </div>

        <div className={styles['bottom-block']}>
            <div className={styles.progress}>
                <Typography className={styles['field-title']}>Прогресс</Typography>

                <ProgressBar
                    progress={getProgress(
                        result.progressStartValue,
                        result.progressFactValue,
                        result.progressEndValue
                    )}
                />

                {result.progressUnit === 0 ? (
                    <Typography className={styles['progress-values']}>
                        с {result.progressStartValue} <TrendingFlatIcon />
                        дo {result.progressEndValue}
                    </Typography>
                ) : (
                    <ProgressValue
                        progressStartValue={result.progressStartValue}
                        progressEndValue={result.progressEndValue}
                        progressUnit={result.progressUnit}
                    />
                )}
            </div>

            <div className={styles['deadline-block']}>
                <Typography className={styles['field-title']}>Дедлайн</Typography>

                <Typography className={styles.title}>{formatDate(result.dateDeadline)}</Typography>
            </div>

            <div className={styles.responsible}>
                <Typography className={styles['field-title']}>Ответственный</Typography>

                <Typography className={styles.title}>
                    {result.assignedUsers
                        .filter((person) => person !== null)
                        .map((person) => person?.personName ?? '')
                        .join(', ')}
                </Typography>
            </div>
        </div>
    </div>
);
