import { FC, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { Button, Card, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

import { EmptyResult } from 'src/components/empty-result/empty-result';
import { Objective } from 'src/api/okr/objective/objective.types';
import { KeyResult } from 'src/api/okr/key-result/key-result.types';

import { ObjectiveAccordion } from '../objective-accordion/objective-accordion';

import styles from './subtasks-list.module.css';

interface Props {
    title: string;
    results: KeyResult[] | Objective[];
    icon: ReactNode;
    heading: string;
    editLink: string;
    link: string;
}

export const SubtasksList: FC<Props> = ({ title, results, icon, heading, editLink, link }) => (
    <Card className={styles.block}>
        <div className={styles['heading-block']}>
            {icon}
            <Typography variant="h3" className={styles['accordion-title']}>
                {heading}
            </Typography>

            <div className={styles.dot} />

            <Typography variant="h3" className={styles['heading-objective']}>
                {title}
            </Typography>

            {results.length ? (
                <Button
                    component={Link}
                    to={link}
                    startIcon={<AddIcon />}
                    className={styles.button}
                >
                    Создать {heading}
                </Button>
            ) : null}
        </div>

        <div className={styles.main}>
            {results.length ? (
                results.map((result) => (
                    <ObjectiveAccordion key={result.id} result={result} editLink={editLink} />
                ))
            ) : (
                <EmptyResult
                    title={heading}
                    action={{ component: Link, to: link, children: `Создать ${heading}` }}
                />
            )}
        </div>
    </Card>
);
