import { useMutation, UseMutationOptions } from '@tanstack/react-query';

import { Objective } from 'src/api/okr/objective/objective.types';
import { getWbsById, postWbs } from 'src/api/okr/wbs/wbs.request';
import { CreateWbsPayload } from 'src/api/okr/wbs/wbs.types';
import { queryClient } from 'src/lib/query-client';

export const usePostWbs = (config: UseMutationOptions<Objective, unknown, CreateWbsPayload> = {}) =>
    useMutation<Objective, unknown, CreateWbsPayload>({
        mutationFn: (data) => postWbs(data),
        onSuccess: async () => {
            await queryClient.invalidateQueries({ queryKey: [getWbsById.name] });
        },
        ...config,
    });
