import { QueryClient } from '@tanstack/react-query';

export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            gcTime: 5 * 1000 * 60,
            staleTime: 5 * 1000 * 60,
            retry: false,
        },
    },
});
