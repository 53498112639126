import { NavLink } from 'react-router-dom';
import {
    Drawer as MuiDrawer,
    Collapse,
    List,
    ListItemText,
    ListItemButton,
    ListItemIcon,
    CSSObject,
    Theme,
    styled,
} from '@mui/material';
import {
    HomeFilled,
    IdcardFilled,
    GoldFilled,
    BookFilled,
    RightOutlined,
    BarsOutlined,
    DownOutlined,
    UpOutlined,
} from '@ant-design/icons';
import { useState } from 'react';

import { locations } from 'src/constants/routes';
import { ReactComponent as LogoIcon } from 'src/assets/svg/okr.svg';

// eslint-disable-next-line import/no-cycle
import { useRootLayoutContext } from '../layout';
import { useGetAllWbs } from '../../hooks/use-get-all-wbs';

import styles from './sidebar.module.css';

const SIDEBAR_ITEMS = [
    { title: 'Главная', link: locations.main, icon: HomeFilled, disabled: false },
    { title: 'Мои OKR', link: locations.tree, icon: GoldFilled, disabled: true },
    { title: 'OKR Холдинга', link: locations.okr, icon: IdcardFilled, disabled: true },
];

const drawerWidth = 207;

const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
    border: '0',
    height: 'calc(100% - 46px)',
    alignSelf: 'end',
    bottom: 0,
});

const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    height: 'calc(100% - 46px)',
    alignSelf: 'end',
    bottom: 0,
    width: 0,
    [theme.breakpoints.up('sm')]: {
        width: 0,
    },
});

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    })
);

export const Sidebar = () => {
    const [open, setOpen] = useState(true);
    const { controlsHidden } = useRootLayoutContext();
    const { data: wbsList } = useGetAllWbs();

    const handleClick = () => {
        setOpen(!open);
    };

    return (
        <Drawer
            className={styles.root}
            variant="permanent"
            classes={{
                paper: styles.drawerPaper,
            }}
            anchor="left"
            open={!controlsHidden}
        >
            <LogoIcon className={styles.logo} />

            <List component="nav" className={styles.navigation}>
                <div>
                    {SIDEBAR_ITEMS.map(({ title, link, icon: Icon, disabled }) => (
                        <ListItemButton
                            key={title}
                            component={NavLink}
                            to={link}
                            disabled={disabled}
                            className={styles['list-item']}
                        >
                            <ListItemIcon className={styles['icon-wrapper']}>
                                <Icon className={styles.icon} />
                            </ListItemIcon>
                            <ListItemText primary={title} />
                        </ListItemButton>
                    ))}
                    <ListItemButton onClick={handleClick} className={styles['list-item']}>
                        <ListItemIcon className={styles['icon-wrapper']}>
                            <BarsOutlined className={styles.icon} />
                        </ListItemIcon>
                        <ListItemText primary="Администрирование" />
                        {open ? <DownOutlined /> : <UpOutlined />}
                    </ListItemButton>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <List component="div">
                            {wbsList?.map((wbs) => (
                                <ListItemButton
                                    key={wbs.id}
                                    component={NavLink}
                                    to={`${locations.wbs.replace(':id', wbs.id)}/tree`}
                                    className={styles['list-item']}
                                    style={{ paddingLeft: '40px' }}
                                >
                                    <ListItemText primary={wbs?.title} />
                                </ListItemButton>
                            ))}
                            <ListItemButton
                                component={NavLink}
                                to={locations.objectives}
                                className={styles['list-item']}
                                style={{ paddingLeft: '40px' }}
                            >
                                <ListItemText primary="Objectives" />
                            </ListItemButton>
                            <ListItemButton
                                component={NavLink}
                                to={locations.createWBS}
                                className={styles['list-item']}
                                style={{ paddingLeft: '40px' }}
                            >
                                <ListItemText primary="+ Создать WBS" />
                            </ListItemButton>
                        </List>
                    </Collapse>
                </div>

                <div>
                    <ListItemButton
                        key="Выйти"
                        component={NavLink}
                        to="/"
                        className={styles['list-item']}
                    >
                        <ListItemIcon className={styles['icon-wrapper']}>
                            <BookFilled className={styles.icon} />
                        </ListItemIcon>
                        <ListItemText primary="Выйти" />
                        <RightOutlined className={styles.icon} />
                    </ListItemButton>
                </div>
            </List>
        </Drawer>
    );
};
