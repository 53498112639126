import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { ru } from 'date-fns/locale';
import NiceModal from '@ebay/nice-modal-react';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import {
    LocalizationProvider as MuiDatePickersLocalizationProvider,
    ruRU,
} from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { QueryClientProvider } from '@tanstack/react-query';

import { App } from 'src/app/app';
import { theme } from 'src/providers/theme-provider';
import { queryClient } from 'src/lib/query-client';

const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container);

root.render(
    <BrowserRouter>
        <QueryClientProvider client={queryClient}>
            <ThemeProvider theme={theme}>
                <StyledEngineProvider injectFirst>
                    <MuiDatePickersLocalizationProvider
                        dateAdapter={AdapterDateFns}
                        adapterLocale={ru}
                        localeText={ruRU.components.MuiLocalizationProvider.defaultProps.localeText}
                    >
                        <CssBaseline />

                        <NiceModal.Provider>
                            <App />
                        </NiceModal.Provider>
                    </MuiDatePickersLocalizationProvider>
                </StyledEngineProvider>
            </ThemeProvider>
        </QueryClientProvider>
    </BrowserRouter>
);
