import { useQuery } from '@tanstack/react-query';

import { getUsersByFilter } from 'src/api/okr/users/users.requests';
import { Assignee } from 'src/api/okr/users/users.types';

export const useGetUsersByFilter = (filter: string) =>
    useQuery<Assignee[]>({
        queryFn: () => getUsersByFilter(filter),
        queryKey: [getUsersByFilter.name, filter],
        enabled: Boolean(filter),
    });
