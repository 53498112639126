import { IconProps } from './types';

export const DollarsIcon = ({
    width = 20,
    height = 20,
    fill = '#6323ED',
    className = '',
}: IconProps) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 20 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
    >
        <path
            d="M10.325 9.58333C8.4333 9.09167 7.82497 8.58333 7.82497 7.79167C7.82497 6.88333 8.66663 6.25 10.075 6.25C11.5583 6.25 12.1083 6.95833 12.1583 8H14C13.9416 6.56667 13.0666 5.25 11.325 4.825V3H8.82497V4.8C7.2083 5.15 5.9083 6.2 5.9083 7.80833C5.9083 9.73333 7.49997 10.6917 9.82497 11.25C11.9083 11.75 12.325 12.4833 12.325 13.2583C12.325 13.8333 11.9166 14.75 10.075 14.75C8.3583 14.75 7.6833 13.9833 7.59163 13H5.7583C5.8583 14.825 7.22497 15.85 8.82497 16.1917V18H11.325V16.2083C12.95 15.9 14.2416 14.9583 14.2416 13.25C14.2416 10.8833 12.2166 10.075 10.325 9.58333Z"
            fill={fill}
        />
    </svg>
);
