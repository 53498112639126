import { useNavigate } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import { PlusOutlined, RightOutlined } from '@ant-design/icons';
import { Button, Table } from 'antd';

import { Layout } from 'src/layout/layout';
import { locations } from 'src/constants/routes';
import { useGetObjectiveHierarchy } from 'src/hooks/use-get-objective-hierarchy';
import { addNumberField } from 'src/shared/helpers/add-number-field';

import { columns } from '../okr-list/columns';

import styles from './objectives.module.css';

export const ObjectivesPage = () => {
    const navigate = useNavigate();

    const { data: objectives = [], isLoading, isSuccess } = useGetObjectiveHierarchy();

    const createObjective = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.stopPropagation();

        navigate(locations.createObjectiveWithoutParent);
    };

    return (
        <Layout
            headerProps={{
                title: 'Список',
                showTabs: false,
                showNotifications: true,
                showActions: true,
            }}
        >
            <div className={styles['list-header']}>
                <h2 className={styles['list-header-title']}>Objectives</h2>
                <Button type="primary" icon={<PlusOutlined />} onClick={createObjective}>
                    Создать
                </Button>
            </div>
            {isLoading && <CircularProgress />}

            {/* {isSuccess && objectives.length === 0 && (
                <EmptyResult
                    title={HEADING}
                    action={{
                        component: Link,
                        to: locations.createOKR,
                        children: `Создать ${HEADING}`,
                    }}
                />
            )} */}

            {isSuccess && (
                <div className={styles.container}>
                    <Table
                        // @ts-ignore
                        columns={columns}
                        dataSource={addNumberField(objectives)}
                        rowKey="id"
                        rowClassName={styles.row}
                        expandable={{
                            childrenColumnName: 'children',
                            defaultExpandAllRows: true,
                            indentSize: 0,
                            // eslint-disable-next-line react/no-unstable-nested-components
                            expandIcon: ({ expanded, onExpand, record }) => (
                                // eslint-disable-next-line jsx-a11y/no-static-element-interactions
                                <div
                                    onClick={(e) => onExpand(record, e)}
                                    className={styles['row-icon']}
                                    data-hierarchical={
                                        record.number?.length === 1 ? 0 : record?.number?.charAt(2)
                                    }
                                >
                                    {record.children.length > 0 && (
                                        <RightOutlined
                                            color="#1D1D1F"
                                            className={expanded ? styles['icon-rotate'] : ''}
                                        />
                                    )}
                                </div>
                            ),
                        }}
                    />
                </div>
            )}
        </Layout>
    );
};
