import { FC, useMemo } from 'react';
import { CardContent, Card, Typography, CardHeader, Button, IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import NiceModal from '@ebay/nice-modal-react';
import AddIcon from '@mui/icons-material/Add';
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import ArrowDropUpOutlinedIcon from '@mui/icons-material/ArrowDropUpOutlined';
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';
import { ClockCircleOutlined, UserOutlined } from '@ant-design/icons';

import { AssignedUsersTooltip } from 'src/components/assigned-users-tooltip/assigned-users-tooltip';
import { ProgressBar } from 'src/components/progress-bar/progress-bar';
import { Objective, ProgressUnit } from 'src/api/okr/objective/objective.types';
import { locations } from 'src/constants/routes';
import { getProgress } from 'src/utils/get-progress';
import { formatDate } from 'src/utils/format-date';
import { ObjectiveDrawer } from 'src/widgets/objective-drawer/objective-drawer';
import { TitleTooltip } from 'src/components/title-tooltip/title-tooltip';
import { ProgressValue } from 'src/components/progress-value/progress-value';
import { progressUnitsValues } from 'src/constants/progress-units-values';
import { FullIcon } from 'src/shared/ui/icons';
import { useGetObjectiveHierarchy } from 'src/hooks/use-get-objective-hierarchy';
import { getNodeNestedLevel } from 'src/utils/get-node-nested-level';

import styles from './okr-card.module.css';

interface Props {
    hasChildren: boolean;
    onClickArrow: (id: string) => void;
    objective: Objective;
    hierarchicalNum: string | null;
}

export const OkrCard: FC<Props> = ({ objective, hierarchicalNum, onClickArrow }) => {
    const navigate = useNavigate();

    const { data: objectives = [] } = useGetObjectiveHierarchy();

    const progressUnit = useMemo(
        () => progressUnitsValues.find((unit) => unit.id === objective.progressUnit),
        [progressUnitsValues, objective]
    );

    const openDrawer = (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation();

        NiceModal.show(ObjectiveDrawer, {
            objective,
            title: hierarchicalNum ? `Objective ${hierarchicalNum}` : 'OKR',
        });
    };

    const createObjective = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.stopPropagation();

        navigate(locations.createObjective.replace(':id', objective.id));
    };

    const handeOnClickArrow = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        e.stopPropagation();

        onClickArrow(objective.id);
    };

    const handleOnClickFull = (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation();
        e.preventDefault();
        navigate(locations.objective.replace(':id', objective.id));
    };

    const iconHeight = progressUnit?.id === ProgressUnit.Billion ? 11 : 20;

    const showMetrics = useMemo(
        () => getNodeNestedLevel(objective.id, objectives) > 1,
        [objectives, objective.id]
    );

    return (
        <Card
            className={styles.root}
            data-hierarchical={hierarchicalNum?.length === 1 ? 0 : hierarchicalNum?.charAt(2)}
            onClick={openDrawer}
        >
            <div className={styles.banner}>
                <Typography className={styles['banner-title']}>
                    {objective?.header ?? `Objective ${hierarchicalNum}`}
                </Typography>

                <div className={styles['banner-big-icon']}>
                    {showMetrics && progressUnit?.icon && (
                        <progressUnit.icon fill="white" height={iconHeight} />
                    )}
                </div>
            </div>

            <CardHeader
                title={<TitleTooltip title={objective.title} lineClamp={2} />}
                action={
                    <Button
                        className={styles['button-edit']}
                        aria-label="Редактировать"
                        onClick={handleOnClickFull}
                    >
                        <FullIcon />
                    </Button>
                }
                className={styles.header}
            />

            <CardContent className={styles.main}>
                <div className={styles['card-content']}>
                    <Typography className={styles.title}>
                        <UserOutlined width={18} height={18} />
                    </Typography>
                    <Typography className={styles['card-info']}>
                        <AssignedUsersTooltip assignedUsers={objective.assignedUsers} />
                    </Typography>
                </div>

                <div className={styles['card-content']}>
                    <Typography className={styles.title}>
                        <ClockCircleOutlined width={18} height={18} />
                    </Typography>
                    <Typography className={styles['deadline-text']}>
                        {formatDate(objective.dateDeadline)}
                    </Typography>
                </div>

                {showMetrics && (
                    <div className={styles['bottom-block']}>
                        <div className={styles.progress}>
                            <ProgressBar
                                progress={getProgress(
                                    objective.progressStartValue,
                                    objective.progressFactValue,
                                    objective.progressEndValue
                                )}
                            />
                            {objective.progressUnit === 0 ? (
                                <Typography className={styles['progress-values']}>
                                    с {objective.progressStartValue} <TrendingFlatIcon />
                                    дo {objective.progressEndValue}
                                </Typography>
                            ) : (
                                <ProgressValue
                                    progressEndValue={objective.progressEndValue}
                                    progressUnit={objective.progressUnit}
                                    progressStartValue={objective.progressStartValue}
                                />
                            )}
                        </div>
                    </div>
                )}

                <Button
                    onClick={createObjective}
                    endIcon={<AddIcon />}
                    className={styles.button}
                    fullWidth
                >
                    Добавить
                </Button>

                {objective.hasChildren && (
                    <IconButton className={styles['bottom-icon']} onClick={handeOnClickArrow}>
                        {objective.children.length > 0 ? (
                            <ArrowDropDownOutlinedIcon />
                        ) : (
                            <ArrowDropUpOutlinedIcon />
                        )}
                    </IconButton>
                )}
            </CardContent>
        </Card>
    );
};
