import { FC, ReactElement, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { DrawerProps, IconButton, Typography } from '@mui/material';
import { Drawer, Button as AntdButton, Dropdown, MenuProps, Modal } from 'antd';
import KeyIcon from '@mui/icons-material/Key';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CancelIcon from '@mui/icons-material/Cancel';
import {
    DoubleRightOutlined,
    EditOutlined,
    ExclamationCircleOutlined,
    MoreOutlined,
    PlusOutlined,
} from '@ant-design/icons';

import { Objective, PutObjectivePayload } from 'src/api/okr/objective/objective.types';
import { ObjectiveCard } from 'src/pages/objective/objective-card/objective-card';
import { locations } from 'src/constants/routes';
import { SubtasksListItem } from 'src/components/subtasks-list-item/subtasks-list-item';
import { ObjectiveForm } from 'src/widgets/objective-form/objective-form';
import { EmptyResult } from 'src/components/empty-result/empty-result';
import { KeyResult, PutKeyResultPayload } from 'src/api/okr/key-result/key-result.types';
import { queryClient } from 'src/lib/query-client';
import { useDeleteObjective } from 'src/hooks/use-delete-objective';
import { usePutKeyResult } from 'src/hooks/use-put-key-result';
import { usePutObjective } from 'src/hooks/use-put-objective';
import { getWbsById } from 'src/api/okr/wbs/wbs.request';
import { getObjectiveHierarchy } from 'src/api/okr/objective/objective.requests';

import { Confirm } from '../confirm/confirm';

import styles from './objective-drawer.module.css';

interface Props extends DrawerProps {
    objective: Objective;
    title: string;
}

const showSuccessModal = () =>
    NiceModal.show(Confirm, {
        icon: <CheckBoxIcon color="success" />,
        title: 'Изменения сохранены',
        text: 'Не забудьте проверить результат и продолжить работу',
        actions: {
            accept: {
                children: 'Продолжить',
                onClick: () => NiceModal.hide(Confirm),
            },
        },
    });

const showErrorModal = () => {
    NiceModal.show(Confirm, {
        icon: <CancelIcon color="error" />,
        title: 'Что-то пошло не так!',
        text: 'Возможно, возникли технические трудности',
        actions: {
            accept: {
                children: 'Вернуться назад',
                onClick: () => NiceModal.hide(Confirm),
            },
        },
    });
};

export const MyDrawerBase: FC<Props> = ({ objective, title, ...props }) => {
    const [editObjectiveMode, setEditObjectiveMode] = useState(false);
    const [editKeyResult, setEditKeyResult] = useState<KeyResult | null>(null);
    const location = useLocation();
    const [modal, contextHolder] = Modal.useModal();
    const modalHandler = useModal();
    const deleteMutation = useDeleteObjective({
        async onSuccess() {
            await queryClient.invalidateQueries({
                queryKey: [getWbsById.name, getObjectiveHierarchy.name, false],
            });
            await NiceModal.hide(Confirm);
            await modalHandler.hide();
        },
    });

    const handleDelete = () =>
        modal.confirm({
            title: 'Вы уверены?',
            icon: <ExclamationCircleOutlined />,
            content: 'Внесенные данные не будут сохранены',
            okText: 'Продолжить',
            cancelText: 'Отмена',
            onOk: () => deleteMutation.mutate(objective.id),
        });

    const handleSuccess = async () => {
        const wbsId = location.pathname.split('/')?.[2] ?? '';
        await queryClient.invalidateQueries({
            queryKey: [getObjectiveHierarchy.name, false],
        });
        await queryClient.invalidateQueries({
            queryKey: [getWbsById.name, wbsId],
        });

        await modalHandler.hide();

        showSuccessModal();
    };

    const keyResultMutation = usePutKeyResult({
        onError: showErrorModal,
        onSuccess: handleSuccess,
    });

    const objectiveMutation = usePutObjective({
        onError: showErrorModal,
        onSuccess: handleSuccess,
    });

    const items: MenuProps['items'] = [
        {
            key: '1',
            label: (
                <AntdButton aria-label="Обновить прогресс" type="link" style={{ color: 'black' }}>
                    Обновить прогресс
                </AntdButton>
            ),
        },
        {
            key: '2',
            label: (
                <AntdButton aria-label="Скопировать ссылку" type="link" style={{ color: 'black' }}>
                    Скопировать ссылку
                </AntdButton>
            ),
        },
        {
            key: '3',
            label: (
                <AntdButton
                    aria-label="Удалить"
                    onClick={handleDelete}
                    type="link"
                    danger
                    disabled={deleteMutation.isPending}
                >
                    Удалить
                </AntdButton>
            ),
        },
    ];

    return (
        <Drawer
            {...props}
            open={modalHandler.visible}
            onClose={() => {
                setEditObjectiveMode(false);
                modalHandler.hide();
            }}
            width={700}
            zIndex={1300}
            closeIcon={<DoubleRightOutlined />}
            title={editObjectiveMode ? `Редактировать ${title}` : title}
            extra={
                <Dropdown menu={{ items }}>
                    <AntdButton icon={<MoreOutlined />} />
                </Dropdown>
            }
            styles={{
                body: {
                    backgroundColor: '#F8FAFC',
                    padding: '12px 16px',
                },
            }}
        >
            {contextHolder}
            <div className={styles.container}>
                {editObjectiveMode ? (
                    <ObjectiveForm
                        parentId={objective.id}
                        showProgress
                        isModal
                        defaultValues={objective}
                        onCancel={() => setEditObjectiveMode(false)}
                        onSubmit={(data) => {
                            const assignedUsers = data.assignedUsers.map((user) => user?.id);
                            const payload = { ...data, assignedUsers } as PutObjectivePayload;

                            setEditObjectiveMode(false);
                            objectiveMutation.mutate(payload);
                        }}
                    />
                ) : (
                    <div className={`${styles.main} ${editKeyResult && styles.disabled}`}>
                        <div className={styles['title-block']}>
                            <Typography variant="h3" className={styles.heading}>
                                {objective.title}
                            </Typography>

                            <div className={styles['button-block']}>
                                <AntdButton
                                    aria-label="Редактировать"
                                    onClick={() => setEditObjectiveMode(true)}
                                >
                                    Редактировать
                                </AntdButton>
                            </div>
                        </div>

                        <ObjectiveCard objective={objective} showModal />
                        {/* <Button
                            component={Link}
                            to={locations.createObjective.replace(':id', objective.id)}
                            startIcon={<AddIcon />}
                            className={styles.button}
                            onClick={modalHandler.hide}
                        >
                            Создать
                        </Button> */}
                    </div>
                )}

                {objective.keyResults.length > 0 ? (
                    <div className={`${styles['key-results']}`}>
                        <div className={styles['heading-block']}>
                            <KeyIcon className={styles.icon} />

                            <Typography variant="h3" className={styles['accordion-title']}>
                                Key Results
                            </Typography>

                            <AntdButton
                                icon={<PlusOutlined />}
                                onClick={() => {
                                    modalHandler.hide();
                                }}
                                href={locations.createKeyResult.replace(':id', objective.id)}
                                disabled={editKeyResult !== null}
                            >
                                Добавить Key Result
                            </AntdButton>
                        </div>

                        <div className={styles.items}>
                            {editKeyResult ? (
                                <>
                                    <ObjectiveForm
                                        parentId={objective.id}
                                        defaultValues={editKeyResult}
                                        isModal
                                        disableSubmit={keyResultMutation.isPending}
                                        onCancel={() => setEditKeyResult(null)}
                                        onSubmit={(data) => {
                                            const assignedUsers = data.assignedUsers
                                                .filter((person) => person !== null)
                                                .map(({ id }) => id);

                                            const payload = {
                                                ...data,
                                                assignedUsers,
                                            } as unknown as PutKeyResultPayload;

                                            keyResultMutation.mutate(payload);
                                        }}
                                    />

                                    {objective.keyResults.reduce(
                                        (accumulator: ReactElement[], result) => {
                                            if (result !== editKeyResult) {
                                                accumulator.push(
                                                    <div
                                                        key={result.id}
                                                        className={`${styles['disabled-result']} ${styles.withLine}`}
                                                    >
                                                        <SubtasksListItem
                                                            result={result}
                                                            editIcon={
                                                                <IconButton>
                                                                    <BorderColorOutlinedIcon
                                                                        className={styles.edit}
                                                                    />
                                                                </IconButton>
                                                            }
                                                        />
                                                    </div>
                                                );
                                            }
                                            return accumulator;
                                        },
                                        []
                                    )}
                                </>
                            ) : (
                                objective.keyResults.map((result) => (
                                    <div key={result.id} className={styles.withLine}>
                                        <SubtasksListItem
                                            result={result}
                                            editIcon={
                                                <IconButton
                                                    onClick={() => setEditKeyResult(result)}
                                                >
                                                    <EditOutlined className={styles.edit} />
                                                </IconButton>
                                            }
                                        />
                                    </div>
                                ))
                            )}
                        </div>
                    </div>
                ) : (
                    <div className={styles['key-results']}>
                        <div className={styles['heading-block']}>
                            <KeyIcon className={styles.icon} />

                            <Typography variant="h3" className={styles['accordion-title']}>
                                Key Results
                            </Typography>
                        </div>

                        <EmptyResult
                            title="Key Result"
                            action={{
                                component: Link,
                                to: locations.createKeyResult.replace(':id', objective.id),
                                children: `Создать Key Result`,
                                onClick: modalHandler.hide,
                            }}
                        />
                    </div>
                )}
            </div>
        </Drawer>
    );
};

export const ObjectiveDrawer = NiceModal.create(MyDrawerBase);
