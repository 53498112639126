import { useQuery } from '@tanstack/react-query';

import { getAllWbs } from '../api/okr/wbs/wbs.request';
import { Objective } from '../api/okr/objective/objective.types';

export const useGetAllWbs = () =>
    useQuery<Objective[]>({
        queryFn: () => getAllWbs(),
        queryKey: [getAllWbs.name],
    });
