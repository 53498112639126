import { IconProps } from './types';

export const NumberIcon = ({
    width = 20,
    height = 20,
    fill = '#6323ED',
    className = '',
}: IconProps) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 20 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
    >
        <path
            d="M6.0415 13H4.7915V9.25H3.5415V8H6.0415V13ZM11.4582 11.75H8.95817V10.9167H10.6248C11.0832 10.9167 11.4582 10.5417 11.4582 10.0833V8.83333C11.4582 8.375 11.0832 8 10.6248 8H7.70817V9.25H10.2082V10.0833H8.5415C8.08317 10.0833 7.70817 10.4583 7.70817 10.9167V13H11.4582V11.75ZM16.4582 12.1667V8.83333C16.4582 8.375 16.0832 8 15.6248 8H12.7082V9.25H15.2082V10.0833H13.5415V10.9167H15.2082V11.75H12.7082V13H15.6248C16.0832 13 16.4582 12.625 16.4582 12.1667Z"
            fill={fill}
        />
    </svg>
);
