import { useMemo, useState } from 'react';
import { Button, Col, Drawer, Form, FormProps, Input, Row, Select, Space } from 'antd';

import { Layout } from 'src/layout/layout';
import { EmptyResult } from 'src/components/empty-result/empty-result';
import { queryClient } from 'src/lib/query-client';
import { getAllWbs } from 'src/api/okr/wbs/wbs.request';
import { useGetObjectiveHierarchy } from 'src/hooks/use-get-objective-hierarchy';

import { usePostWbs } from '../../hooks/use-post-wbs';

import styles from './create-wbs.module.css';

const HEADING = 'WBS';

const { Option } = Select;

type FieldType = {
    title: string;
    taskId: string;
};

export const CreateWBS = () => {
    const [open, setOpen] = useState(false);

    const { data: objectives = [] } = useGetObjectiveHierarchy();

    const { mutateAsync } = usePostWbs({
        onSuccess: async () => {
            await queryClient.invalidateQueries({ queryKey: [getAllWbs.name] });
        },
    });
    const [form] = Form.useForm();

    const options = useMemo(
        () =>
            objectives.map((objective) => ({
                label: objective.title,
                value: objective.id,
            })),
        [objectives]
    );

    const showDrawer = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    const onSubmit: FormProps<FieldType>['onFinish'] = async (data) => {
        try {
            await mutateAsync({
                ...data,
                creatorId: '00f3d9b3-c132-41d2-b4ac-419b625a2964',
                isDeleted: false,
            });
        } catch (err) {
            /* empty */
        } finally {
            onClose();
        }
    };

    const filterOption = (
        input: string,
        option?: { label: string; value: string; children?: string }
    ) => (option?.children ?? '').toLowerCase().includes(input.toLowerCase());

    return (
        <Layout
            headerProps={{
                title: 'Список',
                showTabs: false,
                showNotifications: true,
                showActions: true,
            }}
        >
            <EmptyResult
                title={HEADING}
                action={{
                    onClick: showDrawer,
                    children: `Создать ${HEADING}`,
                }}
            />
            <Drawer
                title="Создать WBS"
                onClose={onClose}
                closeIcon={null}
                open={open}
                width={700}
                zIndex={1600}
                extra={
                    <Space>
                        <Button onClick={onClose}>Отмена</Button>
                        <Button
                            type="primary"
                            htmlType="submit"
                            onClick={() => {
                                form.submit();
                            }}
                        >
                            Сохранить
                        </Button>
                    </Space>
                }
            >
                <Form layout="vertical" onFinish={onSubmit} form={form}>
                    <h3 className={styles.title}>Основная информация</h3>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="title"
                                label="Наименование WBS"
                                rules={[{ required: true, message: 'Введите наименование WBS' }]}
                            >
                                <Input placeholder="Введите наименование WBS" allowClear />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item name="taskId" label="Objective">
                                <Select
                                    placeholder="Выберите Objective"
                                    allowClear
                                    showSearch
                                    filterOption={filterOption}
                                >
                                    {options.map((option) => (
                                        <Option key={option.value}>{option.label}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Drawer>
        </Layout>
    );
};
