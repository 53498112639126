import { UseQueryOptions, useQuery } from '@tanstack/react-query';

import { getObjectiveById } from 'src/api/okr/objective/objective.requests';
import { Objective } from 'src/api/okr/objective/objective.types';

type Config = Partial<UseQueryOptions<Objective>>;

export const useGetObjective = (id: string, config?: Config) =>
    useQuery<Objective>({
        queryFn: () => getObjectiveById(id),
        queryKey: [getObjectiveById.name, id],
        ...config,
    });
