import {
    NumberIcon,
    PercentIcon,
    DollarsIcon,
    TengeIcon,
    TimeIcon,
    MillionIcon,
    BillionIcon,
} from 'src/shared/ui/icons';

import { ProgressUnit } from './progress-unit';

export const progressUnitsValues = [
    { id: ProgressUnit.Number, name: 'Число', icon: NumberIcon, shortName: '' },
    { id: ProgressUnit.Percentage, name: 'Процент', icon: PercentIcon, shortName: '%' },
    { id: ProgressUnit.Dollar, name: 'Доллар', icon: DollarsIcon, shortName: 'доллар' },
    { id: ProgressUnit.Tenge, name: 'Тенге', icon: TengeIcon, shortName: 'тенге' },
    { id: ProgressUnit.Hour, name: 'Часы', icon: TimeIcon, shortName: 'ч' },
    { id: ProgressUnit.Million, name: 'Миллион', icon: MillionIcon, shortName: 'млн' },
    { id: ProgressUnit.Billion, name: 'Миллиард', icon: BillionIcon, shortName: 'млрд' },
];
