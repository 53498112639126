import { FC } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import NiceModal from '@ebay/nice-modal-react';
import { Button, Card, Typography } from '@mui/material';
import { Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import { ProgressBar } from 'src/components/progress-bar/progress-bar';
import { locations } from 'src/constants/routes';
import { getProgress } from 'src/utils/get-progress';
import { formatDate } from 'src/utils/format-date';
import { ObjectiveTags } from 'src/pages/objective/objective-tags/objective-tags';
import { Objective } from 'src/api/okr/objective/objective.types';
import { useDeleteObjective } from 'src/hooks/use-delete-objective';
import { Confirm } from 'src/widgets/confirm/confirm';
import { queryClient } from 'src/lib/query-client';
import { getObjectiveHierarchy } from 'src/api/okr/objective/objective.requests';

import { ObjectiveBlock } from '../objective-block/objective-block';
import { ObjectiveField } from '../objective-field/objective-field';

import styles from './objective-card.module.css';

interface Props {
    objective: Objective;
    showModal?: boolean;
}

export const ObjectiveCard: FC<Props> = ({ objective, showModal }) => {
    const navigate = useNavigate();
    const [modal, contextHolder] = Modal.useModal();

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { mutate, isPending } = useDeleteObjective({
        async onSuccess() {
            await queryClient.invalidateQueries({ queryKey: [getObjectiveHierarchy.name] });

            await NiceModal.hide(Confirm);

            navigate(locations.tree);
        },
    });

    const handleDelete = () =>
        modal.confirm({
            title: 'Вы уверены?',
            icon: <ExclamationCircleOutlined />,
            content: 'Внесенные данные не будут сохранены',
            okText: 'Продолжить',
            cancelText: 'Отмена',
        });

    return (
        <Card className={styles.card}>
            {contextHolder}
            {!showModal && (
                <div className={styles['heading-block']}>
                    <Typography variant="h3" className={styles.heading}>
                        {objective.title}
                    </Typography>

                    <div className={styles['button-block']}>
                        <Button
                            disabled={isPending}
                            className={styles.button}
                            onClick={handleDelete}
                        >
                            Удалить
                        </Button>

                        <Button
                            component={Link}
                            to={locations.editObjective.replace(':id', objective.id)}
                            state={{ objective }}
                            className={styles.button}
                        >
                            Редактировать
                        </Button>

                        <Button className={styles.button}>Обновить прогресс</Button>
                    </div>
                </div>
            )}

            <div className={styles.info}>
                <Typography variant="h5" className={styles.title}>
                    Основная информация
                </Typography>

                <ObjectiveField title="Название цели:" field={objective.title} />

                <ObjectiveField title="Описание цели:" field={objective.description} />

                <ObjectiveField
                    title="Ответственные"
                    field={objective.assignedUsers
                        .filter((person) => person !== null)
                        .map(({ personName }) => personName)
                        .join(', ')}
                />
                <ObjectiveBlock title="Прогресс">
                    <div className={styles['progress-container']}>
                        <div className={styles.line}>
                            <ProgressBar
                                progress={getProgress(
                                    objective.progressStartValue,
                                    objective.progressFactValue,
                                    objective.progressEndValue
                                )}
                            />
                        </div>

                        <div className={styles['data-fields']}>
                            <ObjectiveField
                                title="Фактический результат"
                                field={objective.progressFactValue}
                            />

                            <ObjectiveField
                                title="Планируемый результат"
                                field={objective.progressEndValue}
                            />
                        </div>
                    </div>
                </ObjectiveBlock>

                <ObjectiveBlock title="Дедлайн">
                    <div className={`${styles['data-fields']} ${styles.dates}`}>
                        <ObjectiveField title="Начало" field={formatDate(objective.dateStart)} />

                        <ObjectiveField title="Конец" field={formatDate(objective.dateDeadline)} />
                    </div>
                </ObjectiveBlock>

                <div className={styles['tags-block']}>
                    <Typography className={styles['tag-title']}>Теги</Typography>

                    <ObjectiveTags tags={['Все', 'Выручка', 'PHP', 'Go']} />
                </div>
            </div>
        </Card>
    );
};
