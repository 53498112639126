import { okrClient } from '../okr.client';

import { Assignee } from './users.types';

export const getUsersByFilter = async (filter: string) => {
    const { data } = await okrClient.get<Assignee[]>('/user/filter', {
        params: {
            nameStartsWith: filter,
        },
    });

    return data;
};
