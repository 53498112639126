import { okrClient } from '../okr.client';
import { Objective } from '../objective/objective.types';

import { CreateWbsPayload, Wbs } from './wbs.types';

export const getAllWbs = async () => {
    const { data } = await okrClient.post<Objective[]>('/wbs/get-all-wbs');

    return data;
};

export const postWbs = async (form: CreateWbsPayload) => {
    const { data } = await okrClient.post<Objective>('/wbs/create-wbs', form);

    return data;
};

export const updateWbs = async (form: CreateWbsPayload) => {
    const { data } = await okrClient.post<Objective>('/wbs/update-wbs', form);

    return data;
};

export const deleteWbs = async (id?: string) => {
    const wbsId = JSON.stringify(id);

    const { data } = await okrClient.post<Wbs>('/wbs/delete-wbs', wbsId, {
        headers: {
            accept: 'text/plain',
            'Content-Type': 'application/json',
        },
    });

    return data;
};

export const getWbsById = async (id?: string) => {
    const wbsId = JSON.stringify(id);

    const { data } = await okrClient.post<Wbs>('/wbs/get-wbs-by-id', wbsId, {
        headers: {
            accept: 'text/plain',
            'Content-Type': 'application/json',
        },
    });

    return [data];
};
