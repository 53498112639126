import { useMutation, UseMutationOptions } from '@tanstack/react-query';

import { postFilter } from 'src/api/okr/objective/filter/filter.request';
import { Filters } from 'src/api/okr/objective/filter/filter.types';

export const usePostFilter = (config: UseMutationOptions<unknown, unknown, Filters> = {}) =>
    useMutation<unknown, unknown, Filters>({
        mutationFn: (data) => postFilter(data),
        ...config,
    });
