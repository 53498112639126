import { useNavigate, useParams } from 'react-router-dom';
import { notification } from 'antd';

import { locations } from 'src/constants/routes';
import { Layout } from 'src/layout/layout';
import { ObjectiveForm } from 'src/widgets/objective-form/objective-form';
import { queryClient } from 'src/lib/query-client';
import { getObjectiveById, getObjectiveHierarchy } from 'src/api/okr/objective/objective.requests';
import { usePostKeyResult } from 'src/hooks/use-post-key-result';
import { PostKeyResultPayload } from 'src/api/okr/key-result/key-result.types';

import styles from './create-key-result.page.module.css';

export const CreateKeyResultPage = () => {
    const params = useParams();
    const navigate = useNavigate();
    const [api, contextHolder] = notification.useNotification();

    const { mutate, isPending } = usePostKeyResult({
        async onSuccess() {
            await queryClient.invalidateQueries({ queryKey: [getObjectiveHierarchy.name] });

            await queryClient.invalidateQueries({
                queryKey: [getObjectiveById.name, params.id],
            });

            navigate(locations.objectives);
        },

        onError: () => {
            api.error({
                message: 'Что-то пошло не так',
                description: 'Возможно, возникли технические трудности',
            });
        },
    });

    return (
        <Layout>
            {contextHolder}
            <div className={styles.container}>
                <ObjectiveForm
                    parentId={params.id}
                    title="Создать Key Result"
                    disableSubmit={isPending}
                    onCancel={() => navigate(-1)}
                    onSubmit={(data) => {
                        const assignedUsers = data.assignedUsers
                            .filter((person) => person !== null)
                            .map(({ id }) => id);
                        const payload = {
                            ...data,
                            assignedUsers,
                        } as unknown as PostKeyResultPayload;

                        mutate(payload);
                    }}
                />
            </div>
        </Layout>
    );
};
