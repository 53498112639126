import { useMutation, UseMutationOptions } from '@tanstack/react-query';

import { postObjective } from 'src/api/okr/objective/objective.requests';
import { Objective, PostObjectivePayload } from 'src/api/okr/objective/objective.types';

export const usePostObjective = (
    config: UseMutationOptions<Objective, unknown, PostObjectivePayload> = {}
) =>
    useMutation<Objective, unknown, PostObjectivePayload>({
        mutationFn: (data) => postObjective(data),
        ...config,
    });
