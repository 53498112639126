import { FC } from 'react';
import { Tag } from 'antd';

import styles from './objective-tags.module.css';

interface Props {
    tags: string[];
}

export const ObjectiveTags: FC<Props> = ({ tags }) => (
    <div className={styles.block}>
        {tags.slice(0, 3).map((option) => (
            <Tag key={option} bordered={false} color="processing">
                {option}
            </Tag>
        ))}

        {tags.length > 3 && <Tag bordered={false} color="processing">{`+${tags.length - 3}`}</Tag>}
    </div>
);
