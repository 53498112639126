import { useMemo } from 'react';
import { Table } from 'antd';
import { RightOutlined } from '@ant-design/icons';
import { Link, useParams, useLocation } from 'react-router-dom';
import { CircularProgress } from '@mui/material';

import { Layout } from 'src/layout/layout';
import { EmptyResult } from 'src/components/empty-result/empty-result';
import { locations } from 'src/constants/routes';
import { useGetWbsById } from 'src/hooks/use-get-wbs-by-id';
import { addNumberField } from 'src/shared/helpers/add-number-field';
import { Wbs } from 'src/api/okr/wbs/wbs.types';

import styles from './okr-list.module.css';
import { columns } from './columns';
import { gantaColumns } from './ganta-columns';

const HEADING = 'WBS';

const mergeChildrenRecursively = <T extends Wbs>(items?: T[]): Wbs[] =>
    items?.map((item) => {
        // @ts-ignore
        const keyResults = item?.keyResults?.map((results) => ({
            ...results,
            type: 'key_results',
        }));

        const children = [...(item.children || []), ...(keyResults || [])];

        return {
            ...item,
            children: children?.length > 0 ? mergeChildrenRecursively(children) : undefined,
        };
    }) as Wbs[];

const getDefaultExpandedRowKeys = (data: unknown[], level = 0, maxLevel = 0) => {
    let expandedRowKeys: string[] = [];

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data.forEach((row: any) => {
        if (level <= maxLevel && row.children) {
            expandedRowKeys.push(row.id);
            expandedRowKeys = expandedRowKeys.concat(
                getDefaultExpandedRowKeys(row.children, level + 1, maxLevel)
            );
        }
    });
    return expandedRowKeys;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function removeDuplicatesByIdRecursively(data: any[], seenIds = new Set()) {
    return data.reduce((acc, item) => {
        if (seenIds.has(item.id)) {
            return acc;
        }

        seenIds.add(item.id);

        const newItem = { ...item };

        if (newItem.children) {
            newItem.children = removeDuplicatesByIdRecursively(newItem.children, seenIds);
        }

        return [...acc, newItem];
    }, []);
}

export const WbsLisPage = () => {
    const params = useParams();
    const location = useLocation();
    const { data: wbs, isLoading, isSuccess } = useGetWbsById(params?.id);

    const tableColumns = useMemo(() => {
        if (location.pathname.includes('/ganta')) {
            return gantaColumns;
        }

        return columns;
    }, [location]);

    const tableData = useMemo(() => {
        const uniqWbs = removeDuplicatesByIdRecursively(wbs ?? []);
        const result = addNumberField(uniqWbs);

        if (location.pathname.includes('/ganta')) {
            return result;
        }
        return addNumberField(
            removeDuplicatesByIdRecursively(mergeChildrenRecursively(result as Wbs[]))
        );
    }, [location, wbs]);

    const defaultExpandRowKeys = getDefaultExpandedRowKeys(tableData);

    return (
        <Layout
            headerProps={{
                title: 'Список',
                showTabs: true,
                showNotifications: true,
                showActions: true,
            }}
        >
            {isLoading && <CircularProgress />}

            {isSuccess && wbs.length === 0 && (
                <EmptyResult
                    title={HEADING}
                    action={{
                        component: Link,
                        to: locations.createOKR,
                        children: `Создать ${HEADING}`,
                    }}
                />
            )}

            {isSuccess && (
                <div className={styles.container}>
                    <Table
                        // @ts-ignore
                        columns={tableColumns}
                        dataSource={removeDuplicatesByIdRecursively(tableData)}
                        rowKey="id"
                        pagination={false}
                        scroll={{ x: 2000 }}
                        rowClassName={(record) => {
                            const { number } = record;
                            const level = number?.toString().split('.').length;

                            if (level === 1) {
                                return styles['row-bold'];
                            }

                            if (level === 2) {
                                return styles['row-semibold'];
                            }

                            if (level === 3) {
                                return styles['row-medium'];
                            }

                            if (level === 4) {
                                return styles['row-key-results'];
                            }

                            return styles.row;
                        }}
                        expandable={{
                            childrenColumnName: 'children',
                            defaultExpandedRowKeys: defaultExpandRowKeys,
                            indentSize: 0,
                            // eslint-disable-next-line react/no-unstable-nested-components
                            expandIcon: ({ expanded, onExpand, record }) => (
                                // eslint-disable-next-line jsx-a11y/no-static-element-interactions
                                <div
                                    onClick={(e) => onExpand(record, e)}
                                    className={styles['row-icon']}
                                    data-hierarchical={
                                        record.number?.length === 1 ? 0 : record?.number?.charAt(2)
                                    }
                                >
                                    {record?.children?.length > 0 && (
                                        <RightOutlined
                                            color="#1D1D1F"
                                            className={expanded ? styles['icon-rotate'] : ''}
                                        />
                                    )}
                                </div>
                            ),
                        }}
                    />
                </div>
            )}
        </Layout>
    );
};
