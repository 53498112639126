import { FC, useState } from 'react';
import { Controller, ControllerRenderProps, SubmitHandler, useForm } from 'react-hook-form';
import {
    Autocomplete,
    Button,
    Checkbox,
    Chip,
    FormControl,
    FormLabel,
    IconButton,
    InputAdornment,
    ListItemIcon,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Select,
    Slider,
    TextField,
    Typography,
} from '@mui/material';
import { Button as AntdButton, Divider, Modal } from 'antd';
import CancelIcon from '@mui/icons-material/Cancel';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import { DatePicker } from '@mui/x-date-pickers';
import { ExclamationCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { useLocation } from 'react-router-dom';

import { useGetUsersByFilter } from 'src/hooks/use-get-users-by-filter';
import { TaskForm } from 'src/types/task-form';
import { ProgressUnit } from 'src/constants/progress-unit';
import { progressUnitsValues } from 'src/constants/progress-units-values';

import { ObjectiveFormProgress } from './objective-form-progress/objective-form-progress';
import styles from './objective-form.module.css';

interface Props {
    title?: string;
    defaultValues?: Partial<TaskForm>;
    parentId?: string | null;
    showProgress?: boolean;
    isModal?: boolean;
    disableSubmit?: boolean;
    disableDelete?: boolean;
    onCancel: () => void;
    onSubmit: SubmitHandler<TaskForm>;
    onDelete?: VoidFunction;
}

export const ObjectiveForm: FC<Props> = ({
    title,
    defaultValues = {},
    parentId = null,
    isModal,
    disableSubmit,
    disableDelete,
    onCancel,
    onSubmit,
    onDelete,
}) => {
    const { pathname } = useLocation();
    const [modal, contextHolder] = Modal.useModal();

    const showHeader = !pathname.includes('/create-key-result');

    const {
        control,
        formState: { isDirty },
        resetField,
        handleSubmit,
        setValue,
        getValues,
        watch,
    } = useForm<TaskForm>({
        defaultValues: { parentId, ...defaultValues },
    });

    const [tagInput, setTagInput] = useState(false);

    const [assigneeInput, setAssigneeInput] = useState('');

    const { data: assignees = [], isLoading } = useGetUsersByFilter(assigneeInput);

    const handleResetField = (name: keyof TaskForm) => resetField(name);

    const assignedUsers = watch('assignedUsers');

    const handleCancel = () => {
        if (!isDirty) {
            onCancel();
            return;
        }

        modal.confirm({
            title: 'Вы уверены?',
            icon: <ExclamationCircleOutlined />,
            content: 'Внесенные данные не будут сохранены',
            okText: 'Продолжить',
            cancelText: 'Отмена',
            onOk: () => onCancel(),
        });
    };

    const handleChangeMetric = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        field: ControllerRenderProps<TaskForm, keyof TaskForm>
    ) => {
        const { value } = e.target;

        if (value === '-') {
            field.onChange('');
        }

        // Convert to number and prevent negative numbers
        if (!Number.isNaN(Number(value)) && Number(value) >= 0) {
            field.onChange(value);
        } else if (value === '') {
            field.onChange('');
        }
    };

    const preventMinusSign = (e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        // Prevent minus sign and other non-numeric characters
        if (e.key === '-' || e.key === '+' || e.key === 'e') {
            e.preventDefault();
        }
    };

    return (
        <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
            {contextHolder}
            {title && (
                <Typography variant="h4" className={styles.heading}>
                    {title}
                </Typography>
            )}

            <div className={styles.field}>
                {showHeader && (
                    <>
                        {' '}
                        <Typography className={styles.title}>Основное</Typography>
                        <Controller
                            name="header"
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                                <FormControl className={styles['form-control']}>
                                    <FormLabel htmlFor={field.name}>
                                        <span className={styles.asterisk}>* </span>

                                        <span className={styles.label}>Название заголовка</span>
                                    </FormLabel>

                                    <OutlinedInput
                                        {...field}
                                        id={field.name}
                                        size="small"
                                        fullWidth
                                        placeholder="Введите название заголовка"
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="clear input"
                                                    className={styles['cancel-icon']}
                                                    onClick={() => handleResetField(field.name)}
                                                >
                                                    <CancelIcon fontSize="small" />
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                </FormControl>
                            )}
                        />
                    </>
                )}

                <Controller
                    name="title"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                        <FormControl className={styles['form-control']}>
                            <FormLabel htmlFor={field.name}>
                                <span className={styles.asterisk}>* </span>

                                <span className={styles.label}>Название цели</span>
                            </FormLabel>

                            <OutlinedInput
                                {...field}
                                id={field.name}
                                size="small"
                                fullWidth
                                placeholder="Введите название"
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="clear input"
                                            className={styles['cancel-icon']}
                                            onClick={() => handleResetField(field.name)}
                                        >
                                            <CancelIcon fontSize="small" />
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                    )}
                />

                <Controller
                    name="description"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                        <FormControl className={styles['form-control']}>
                            <FormLabel htmlFor={field.name}>
                                <span className={styles.label}>Описание цели</span>
                            </FormLabel>

                            <OutlinedInput
                                {...field}
                                id={field.name}
                                size="small"
                                fullWidth
                                placeholder="Введите название"
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="clear input"
                                            className={styles['cancel-icon']}
                                            onClick={() => handleResetField(field.name)}
                                        >
                                            <CancelIcon fontSize="small" />
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                    )}
                />
                <div className={`${styles.row} ${isModal && styles.modal}`}>
                    <Controller
                        name="assignedUsers"
                        control={control}
                        defaultValue={[]}
                        render={({ field }) => (
                            <FormControl className={styles['form-control']}>
                                <FormLabel htmlFor={field.name}>
                                    <span className={styles.asterisk}>* </span>

                                    <span className={styles.label}>Ответственный</span>
                                </FormLabel>

                                <Autocomplete
                                    {...field}
                                    id={field.name}
                                    multiple
                                    disableCloseOnSelect
                                    loading={isLoading}
                                    size="small"
                                    options={assignees}
                                    className={styles['responsible-select']}
                                    inputValue={assigneeInput}
                                    onInputChange={(event, value, reason) => {
                                        if (reason !== 'reset') {
                                            setAssigneeInput(value);
                                        }
                                    }}
                                    onChange={(event, value) => field.onChange(value)}
                                    isOptionEqualToValue={(option, value) =>
                                        option?.id === value?.id
                                    }
                                    getOptionLabel={(user) => user?.personName ?? ''}
                                    renderTags={() => null}
                                    disabled={assignedUsers?.length >= 10}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            placeholder="Выберите ответственного"
                                        />
                                    )}
                                    renderOption={(props, option) => (
                                        <MenuItem {...props} className={styles.option}>
                                            <Checkbox
                                                name={option?.personName}
                                                checked={field.value.some(
                                                    (user) => user?.id === option?.id
                                                )}
                                            />
                                            <ListItemText>{option?.personName}</ListItemText>
                                        </MenuItem>
                                    )}
                                />

                                <Typography className={styles.helper}>
                                    Максимум 10 исполнителей
                                </Typography>

                                {field.value.length ? (
                                    <div className={styles['chip-container']}>
                                        {field.value.map((person) => (
                                            <Chip
                                                key={person?.id}
                                                label={person?.personName}
                                                deleteIcon={<CloseIcon className={styles.close} />}
                                                onDelete={() =>
                                                    setValue(
                                                        'assignedUsers',
                                                        getValues('assignedUsers').filter(
                                                            (user) => user?.id !== person?.id
                                                        )
                                                    )
                                                }
                                            />
                                        ))}
                                    </div>
                                ) : null}
                            </FormControl>
                        )}
                    />
                    <Controller
                        name="priority"
                        control={control}
                        defaultValue={1}
                        render={({ field }) => (
                            <FormControl className={styles['form-control']}>
                                <FormLabel htmlFor={field.name}>
                                    <span className={styles.asterisk}>* </span>

                                    <span className={styles.label}>Приоритизация</span>
                                </FormLabel>

                                <Slider
                                    {...field}
                                    size="small"
                                    className={styles.line}
                                    valueLabelDisplay="auto"
                                    slotProps={{
                                        input: {
                                            id: field.name,
                                        },
                                    }}
                                    min={1}
                                    max={9}
                                />
                                <div className={styles.sizes}>
                                    <Typography variant="body2">1</Typography>

                                    <Typography variant="body2">9</Typography>
                                </div>
                            </FormControl>
                        )}
                    />
                </div>
            </div>
            <div className={styles.field}>
                <Typography className={styles.title}>Метрики</Typography>
                <div className={styles.metrics}>
                    <div>
                        <Divider orientation="left" className={styles['metric-divider']}>
                            Годовая метрика
                        </Divider>
                        <div className={styles.row}>
                            <Controller
                                name="progressStartValue"
                                control={control}
                                defaultValue={0}
                                render={({ field }) => (
                                    <FormControl className={styles['additional-field']}>
                                        <FormLabel htmlFor={field.name}>
                                            <span className={styles.asterisk}>* </span>

                                            <span className={styles.label}> Начальная</span>
                                        </FormLabel>

                                        <OutlinedInput
                                            {...field}
                                            id={field.name}
                                            size="small"
                                            fullWidth
                                            placeholder="Введите число"
                                            type="number"
                                            onChange={(e) => handleChangeMetric(e, field)}
                                            onKeyDown={preventMinusSign}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="clear input"
                                                        className={styles['cancel-icon']}
                                                        onClick={() => handleResetField(field.name)}
                                                    >
                                                        <CancelIcon fontSize="small" />
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                        />
                                    </FormControl>
                                )}
                            />

                            <Controller
                                name="progressFactValue"
                                control={control}
                                defaultValue={0}
                                render={({ field }) => (
                                    <FormControl className={styles['additional-field']}>
                                        <FormLabel htmlFor={field.name}>
                                            <span className={styles.asterisk}>* </span>

                                            <span className={styles.label}>Текущая</span>
                                        </FormLabel>

                                        <OutlinedInput
                                            {...field}
                                            id={field.name}
                                            size="small"
                                            fullWidth
                                            placeholder="Введите число"
                                            type="number"
                                            onChange={(e) => handleChangeMetric(e, field)}
                                            onKeyDown={preventMinusSign}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="clear input"
                                                        className={styles['cancel-icon']}
                                                        onClick={() => handleResetField(field.name)}
                                                    >
                                                        <CancelIcon fontSize="small" />
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                        />
                                    </FormControl>
                                )}
                            />

                            <Controller
                                name="progressEndValue"
                                control={control}
                                defaultValue={0}
                                render={({ field }) => (
                                    <FormControl className={styles['additional-field']}>
                                        <FormLabel htmlFor={field.name}>
                                            <span className={styles.asterisk}>* </span>

                                            <span className={styles.label}> Плановая</span>
                                        </FormLabel>

                                        <OutlinedInput
                                            {...field}
                                            id={field.name}
                                            size="small"
                                            fullWidth
                                            placeholder="Введите число"
                                            type="number"
                                            onChange={(e) => handleChangeMetric(e, field)}
                                            onKeyDown={preventMinusSign}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="clear input"
                                                        className={styles['cancel-icon']}
                                                        onClick={() => handleResetField(field.name)}
                                                    >
                                                        <CancelIcon fontSize="small" />
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                        />
                                    </FormControl>
                                )}
                            />

                            <Controller
                                name="progressUnit"
                                control={control}
                                render={({ field }) => (
                                    <FormControl className={styles['additional-field']}>
                                        <FormLabel htmlFor={field.name}>
                                            <span className={styles.asterisk}>* </span>

                                            <span className={styles.label}>Измерение</span>
                                        </FormLabel>
                                        <Select
                                            {...field}
                                            size="small"
                                            value={field.value ?? ''}
                                            inputProps={{
                                                id: field.name,
                                            }}
                                            displayEmpty
                                            renderValue={(option: ProgressUnit | string) =>
                                                option === '' ? (
                                                    <span className={styles['select-default']}>
                                                        Выберите измерение
                                                    </span>
                                                ) : (
                                                    progressUnitsValues[option as ProgressUnit].name
                                                )
                                            }
                                        >
                                            {progressUnitsValues.map((unit) => (
                                                <MenuItem
                                                    key={unit.name}
                                                    value={unit.id}
                                                    className={styles['select-option']}
                                                >
                                                    {unit.icon && (
                                                        <ListItemIcon className={styles.unit}>
                                                            <unit.icon />
                                                        </ListItemIcon>
                                                    )}
                                                    <Typography className={styles['option-name']}>
                                                        {unit.name}
                                                    </Typography>
                                                    {unit.id === field.value && (
                                                        <CheckIcon
                                                            className={styles['selected-icon']}
                                                        />
                                                    )}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                )}
                            />
                        </div>
                    </div>
                </div>
                <div className={styles.progress}>
                    <ObjectiveFormProgress control={control} />
                </div>
                <div>
                    <div className={styles.metrics}>
                        <Divider orientation="left" className={styles['metric-divider']}>
                            За III квартал
                        </Divider>
                        <Controller
                            name="progressEndValueQ3"
                            control={control}
                            defaultValue={0}
                            render={({ field }) => (
                                <FormControl className={styles['additional-field']}>
                                    <FormLabel htmlFor={field.name}>
                                        <span className={styles.label}>План</span>
                                    </FormLabel>

                                    <OutlinedInput
                                        {...field}
                                        id={field.name}
                                        size="small"
                                        fullWidth
                                        placeholder="Введите метрику"
                                        type="number"
                                        onChange={(e) => handleChangeMetric(e, field)}
                                        onKeyDown={preventMinusSign}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="clear input"
                                                    className={styles['cancel-icon']}
                                                    onClick={() => handleResetField(field.name)}
                                                >
                                                    <CancelIcon fontSize="small" />
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                </FormControl>
                            )}
                        />
                        <Controller
                            name="progressFactValueQ3"
                            control={control}
                            defaultValue={0}
                            render={({ field }) => (
                                <FormControl className={styles['additional-field']}>
                                    <FormLabel htmlFor={field.name}>
                                        <span className={styles.label}>Фактический</span>
                                    </FormLabel>

                                    <OutlinedInput
                                        {...field}
                                        id={field.name}
                                        size="small"
                                        fullWidth
                                        placeholder="Введите метрику"
                                        type="number"
                                        onChange={(e) => handleChangeMetric(e, field)}
                                        onKeyDown={preventMinusSign}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="clear input"
                                                    className={styles['cancel-icon']}
                                                    onClick={() => handleResetField(field.name)}
                                                >
                                                    <CancelIcon fontSize="small" />
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                </FormControl>
                            )}
                        />
                        <Divider orientation="left" className={styles['metric-divider']}>
                            За IV квартал
                        </Divider>
                        <Controller
                            name="progressEndValueQ4"
                            control={control}
                            defaultValue={0}
                            render={({ field }) => (
                                <FormControl className={styles['additional-field']}>
                                    <FormLabel htmlFor={field.name}>
                                        <span className={styles.label}>План</span>
                                    </FormLabel>

                                    <OutlinedInput
                                        {...field}
                                        id={field.name}
                                        size="small"
                                        fullWidth
                                        placeholder="Введите метрику"
                                        type="number"
                                        onChange={(e) => handleChangeMetric(e, field)}
                                        onKeyDown={preventMinusSign}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="clear input"
                                                    className={styles['cancel-icon']}
                                                    onClick={() => handleResetField(field.name)}
                                                >
                                                    <CancelIcon fontSize="small" />
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                </FormControl>
                            )}
                        />
                        <Controller
                            name="progressFactValueQ4"
                            control={control}
                            defaultValue={0}
                            render={({ field }) => (
                                <FormControl className={styles['additional-field']}>
                                    <FormLabel htmlFor={field.name}>
                                        <span className={styles.label}>Фактический</span>
                                    </FormLabel>

                                    <OutlinedInput
                                        {...field}
                                        id={field.name}
                                        size="small"
                                        fullWidth
                                        placeholder="Введите метрику"
                                        type="number"
                                        onChange={(e) => handleChangeMetric(e, field)}
                                        onKeyDown={preventMinusSign}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="clear input"
                                                    className={styles['cancel-icon']}
                                                    onClick={() => handleResetField(field.name)}
                                                >
                                                    <CancelIcon fontSize="small" />
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                </FormControl>
                            )}
                        />
                    </div>
                </div>
            </div>
            <div className={styles.field}>
                <Typography className={styles.title}>Дедлайн</Typography>

                <div className={styles.row}>
                    <Controller
                        name="dateStart"
                        control={control}
                        // @ts-ignore
                        defaultValue={null}
                        render={({ field }) => (
                            <FormControl className={styles['additional-field']}>
                                <FormLabel htmlFor={field.name}>
                                    <span className={styles.asterisk}>* </span>

                                    <span className={styles.label}> Начало</span>
                                </FormLabel>

                                <DatePicker
                                    {...field}
                                    slotProps={{
                                        textField: {
                                            id: field.name,
                                            placeholder: 'Выберите дату',
                                        },
                                    }}
                                    className={styles.datepicker}
                                />
                            </FormControl>
                        )}
                    />

                    <Controller
                        name="dateDeadline"
                        control={control}
                        // @ts-ignore
                        defaultValue={null}
                        render={({ field }) => (
                            <FormControl className={styles['additional-field']}>
                                <FormLabel htmlFor={field.name}>
                                    <span className={styles.asterisk}>* </span>

                                    <span className={styles.label}>Конец</span>
                                </FormLabel>

                                <DatePicker
                                    {...field}
                                    slotProps={{
                                        textField: {
                                            id: field.name,
                                            placeholder: 'Выберите дату',
                                        },
                                    }}
                                    className={styles.datepicker}
                                />
                            </FormControl>
                        )}
                    />
                </div>
            </div>

            <div className={styles.tags}>
                <Typography className={styles.label}>Теги</Typography>
                <div className={styles['tags-list']}>
                    <Controller
                        name="tags"
                        control={control}
                        defaultValue={[]}
                        render={({ field }) => (
                            <FormControl>
                                {field.value?.length ? (
                                    <div className={styles['chip-container']}>
                                        {field.value.map((tag) => (
                                            <Chip
                                                key={tag}
                                                label={tag}
                                                deleteIcon={<CloseIcon className={styles.close} />}
                                                sx={{ borderRadius: '8px' }}
                                                onDelete={() =>
                                                    setValue(
                                                        'tags',
                                                        getValues('tags').filter(
                                                            (currentTag) => currentTag !== tag
                                                        )
                                                    )
                                                }
                                            />
                                        ))}
                                    </div>
                                ) : null}
                            </FormControl>
                        )}
                    />
                    {tagInput && (
                        <OutlinedInput
                            size="small"
                            placeholder="Тег"
                            onBlur={(e) => {
                                const { value } = e.target;

                                if (value !== '') {
                                    setValue('tags', [...getValues('tags'), value]);
                                }

                                setTagInput(false);
                            }}
                            className={styles['tag-input']}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="clear input"
                                        onMouseDown={(e) => {
                                            e.preventDefault();
                                            setTagInput(false);
                                        }}
                                    >
                                        <CloseIcon fontSize="small" />
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    )}
                    <AntdButton
                        type="text"
                        icon={<PlusOutlined />}
                        onClick={() => setTagInput((prev) => !prev)}
                    >
                        Новый тег
                    </AntdButton>
                </div>
            </div>

            <div className={styles.buttons}>
                <Button className={styles.cancel} onClick={handleCancel}>
                    Отмена
                </Button>

                {onDelete && (
                    <Button className={styles.cancel} disabled={disableDelete} onClick={onDelete}>
                        Удалить
                    </Button>
                )}

                <Button className={styles.create} type="submit" disabled={disableSubmit}>
                    Сохранить
                </Button>
            </div>
        </form>
    );
};
