import { useNavigate, useParams } from 'react-router-dom';
import NiceModal from '@ebay/nice-modal-react';
import CancelIcon from '@mui/icons-material/Cancel';

import { locations } from 'src/constants/routes';
import { Layout } from 'src/layout/layout';
import { ObjectiveForm } from 'src/widgets/objective-form/objective-form';
import { queryClient } from 'src/lib/query-client';
import { getObjectiveById, getObjectiveHierarchy } from 'src/api/okr/objective/objective.requests';
import { Confirm } from 'src/widgets/confirm/confirm';
import { usePostObjective } from 'src/hooks/use-post-objective';
import { PostObjectivePayload } from 'src/api/okr/objective/objective.types';

import styles from './create-objective.page.module.css';

const handleError = () => {
    NiceModal.show(Confirm, {
        icon: <CancelIcon color="error" />,
        title: 'Что-то пошло не так!',
        text: 'Возможно, возникли технические трудности',
        actions: {
            accept: {
                children: 'Вернуться назад',
                onClick: () => NiceModal.hide(Confirm),
            },
        },
    });
};

export const CreateObjectivePage = () => {
    const params = useParams();
    const navigate = useNavigate();

    const { mutate, isPending } = usePostObjective({
        async onSuccess() {
            await queryClient.invalidateQueries({ queryKey: [getObjectiveHierarchy.name] });

            await queryClient.invalidateQueries({
                queryKey: [getObjectiveById.name, params.id],
            });

            navigate(locations.objectives);
        },

        onError: handleError,
    });

    return (
        <Layout>
            <div className={styles.container}>
                <ObjectiveForm
                    title="Создать"
                    parentId={params.id}
                    disableSubmit={isPending}
                    showProgress
                    onSubmit={(data) => {
                        const assignedUsers = data.assignedUsers
                            .filter((person) => person !== null)
                            .map(({ id }) => id);
                        const payload = { ...data, assignedUsers } as PostObjectivePayload;

                        mutate(payload);
                    }}
                    onCancel={() => navigate(-1)}
                />
            </div>
        </Layout>
    );
};
