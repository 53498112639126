import { UseMutationOptions, useMutation } from '@tanstack/react-query';

import { deleteObjective } from 'src/api/okr/objective/objective.requests';
import { Objective } from 'src/api/okr/objective/objective.types';

type Config = Partial<UseMutationOptions<Objective, unknown, string>>;

export const useDeleteObjective = (config?: Config) =>
    useMutation<Objective, unknown, string>({
        mutationFn: (id: string) => deleteObjective(id),
        ...config,
    });
