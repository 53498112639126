import { IconProps } from './types';

export const MillionIcon = ({
    width = 37,
    height = 20,
    fill = '#6323ED',
    className = '',
}: IconProps) => (
    <svg width={width} height={height} className={className}>
        <path
            d="M4.17969 10.8555L5.96094 6.5H7.10156L4.67578 12.5H3.6875L1.31641 6.5H2.44531L4.17969 10.8555ZM2.08984 6.5V12.5H0.726563V6.5H2.08984ZM6.32813 12.5V6.5H7.67969V12.5H6.32813ZM8.25141 12.5L8.24359 11.3359H8.45062C8.60687 11.3359 8.73839 11.3008 8.84516 11.2305C8.95193 11.1602 9.04047 11.043 9.11078 10.8789C9.18109 10.7122 9.23578 10.4883 9.27484 10.207C9.31391 9.92578 9.34125 9.57552 9.35687 9.15625L9.46234 6.5H13.8373V12.5H12.4702V7.66016H10.7475L10.6538 9.59375C10.6303 10.0937 10.5743 10.5273 10.4858 10.8945C10.3998 11.2591 10.2774 11.5599 10.1186 11.7969C9.96234 12.0339 9.76573 12.2109 9.52875 12.3281C9.29177 12.4427 9.01182 12.5 8.68891 12.5H8.25141ZM18.9423 8.91406V10.0742H15.747V8.91406H18.9423ZM16.1845 6.5V12.5H14.8212V6.5H16.1845ZM19.868 6.5V12.5H18.5087V6.5H19.868ZM26.7605 6.33203V12.5H25.3191V6.33203H26.7605ZM29.2449 6.33203V7.53516H22.866V6.33203H29.2449ZM29.2449 4.5V5.70312H22.866V4.5H29.2449Z"
            fill={fill}
        />
    </svg>
);
