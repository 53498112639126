import { Node } from 'reactflow';
import { hierarchy, tree, HierarchyNode, HierarchyPointNode } from 'd3-hierarchy';

import { Objective } from 'src/api/okr/objective/objective.types';
import { createHierarchicalNum } from 'src/utils/create-hierarchical-num';

const NODE_WIDTH = 400;
const NODE_HEIGHT = 350;

const createRoot = (nodes: Objective[]) => {
    const node = hierarchy({ children: nodes } as Objective);

    const treeLayout = tree<Objective>()
        .nodeSize([NODE_WIDTH, NODE_HEIGHT])
        .separation((a, b) => (a.parent === b.parent ? 1 : 1));

    const width = node.height * NODE_WIDTH;
    const height = node.descendants().length * NODE_HEIGHT;

    treeLayout(node);
    treeLayout.size([width, height]);

    return node;
};

export const createTreeLayout = (
    nodes: Objective[],
    rootD3Node?: HierarchyNode<Objective>,
    parentId?: string | null,
    onClickArrow?: (id: string) => void
): Node[] => {
    const rootNode = rootD3Node ?? createRoot(nodes);

    return nodes.flatMap((node, index) => {
        const hierarchicalNum = createHierarchicalNum(parentId, index);
        const d3Node = rootNode
            .descendants()
            .find((d) => d.data === node) as HierarchyPointNode<Objective>;

        const nextNode = {
            id: node.id,
            type: 'custom',
            data: {
                objective: node,
                hierarchicalNum,
                onClickArrow,
                hasChildren: node.children.length > 0,
            },
            position: d3Node ? { x: d3Node.x, y: d3Node.y } : { x: 0, y: 0 },
            children: node.children,
        };

        const children = createTreeLayout(node.children, rootNode, hierarchicalNum, onClickArrow);

        return [nextNode, ...children];
    });
};
