import { ElementType } from 'react';
import { Button, ButtonTypeMap, Typography } from '@mui/material';
import { DefaultComponentProps, OverrideProps } from '@mui/material/OverridableComponent';
import AddIcon from '@mui/icons-material/Add';

import { ReactComponent as EmptyBlock } from 'src/assets/svg/empty-block.svg';

import styles from './empty-result.module.css';

type Action<RootComponent extends ElementType> =
    | ({
          component: RootComponent;
      } & OverrideProps<ButtonTypeMap, RootComponent>)
    | DefaultComponentProps<ButtonTypeMap>;

interface Props<RootComponent extends ElementType> {
    title: string;
    action: Action<RootComponent>;
}

export const EmptyResult = <RootComponent extends ElementType>({
    title,
    action,
}: Props<RootComponent>) => (
    <div className={styles['empty-result']}>
        <EmptyBlock />

        <Typography className={styles.title}>У вас пока нет {title}s</Typography>

        <Button startIcon={<AddIcon />} className={styles.button} {...action} />
    </div>
);
