import { Objective } from 'src/api/okr/objective/objective.types';
import { Wbs } from 'src/api/okr/wbs/wbs.types';

export const addNumberField = (wbs: Array<Objective | Wbs> = [], parentIndices: number[] = []) =>
    wbs?.map((objective, index) => {
        const currentIndices = [...parentIndices, index + 1];

        objective.number = currentIndices.join('.');

        if (objective.children?.length > 0) {
            objective.children = addNumberField(objective.children, currentIndices) as Wbs[];
        }

        if ('task' in objective) {
            return {
                ...objective,
                ...objective.task,
                children: objective.children,
                wbsTitle: objective.title,
            };
        }

        return objective;
    });
