import { okrClient } from '../okr.client';

import { KeyResult, PostKeyResultPayload, PutKeyResultPayload } from './key-result.types';

export const postKeyResult = async (form: PostKeyResultPayload) => {
    const { data } = await okrClient.post<KeyResult>('/keyresult', form);

    return data;
};

export const putKeyResult = async (form: PutKeyResultPayload) => {
    const { data } = await okrClient.put<KeyResult>('/keyresult', form);

    return data;
};

export const deleteKeyResult = async (id: string) => {
    const { data } = await okrClient.delete<KeyResult>(`/keyresult/${id}`);

    return data;
};
