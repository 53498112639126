import { okrClient } from '../okr.client';

import { Objective, PostObjectivePayload, PutObjectivePayload } from './objective.types';

export const getObjectiveHierarchy = async (isOld: boolean) => {
    // @ts-ignore
    const params = new URL(document.location).searchParams;
    const sort = params.get('sort') ?? 0;

    const api = isOld ? '/objective/old-hierarchy' : '/objective/hierarchy';
    const { data } = await okrClient.get<Objective[]>(api, {
        params: {
            sort,
        },
    });

    return data;
};

export const postObjective = async (form: PostObjectivePayload) => {
    const { data } = await okrClient.post<Objective>('/objective', form);

    return data;
};

export const putObjective = async (form: PutObjectivePayload) => {
    const { data } = await okrClient.put<Objective>('/objective', form);

    return data;
};

export const getObjectiveById = async (id: string) => {
    const { data } = await okrClient.get<Objective>(`/objective/${id}`);

    return data;
};

export const deleteObjective = async (id: string) => {
    const { data } = await okrClient.delete<Objective>(`/objective/${id}`);

    return data;
};
