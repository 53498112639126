import {
    createContext,
    FC,
    PropsWithChildren,
    ReactNode,
    useContext,
    useMemo,
    useState,
} from 'react';
import Cookies from 'js-cookie';

import { ObjectiveHeader } from 'src/widgets/objective-header/objective-header';

// eslint-disable-next-line import/no-cycle
import { Sidebar } from './sidebar/sidebar';
// eslint-disable-next-line import/no-cycle
import { Header } from './header/header';
import styles from './layout.module.css';

interface Props {
    children: ReactNode;
    headerProps?: {
        title?: string;
        showTabs?: boolean;
        showNotifications?: boolean;
        showActions?: boolean;
    };
}

interface RootLayoutControlsContextValue {
    controlsHidden: boolean;
    hideControls: () => void;
    showControls: () => void;
}

const RootLayoutControlsContext = createContext<RootLayoutControlsContextValue>(
    {} as RootLayoutControlsContextValue
);

const RootLayoutControlsProvider = ({ children }: PropsWithChildren) => {
    const [hidden, setHidden] = useState(!!Cookies.get('rootLayoutFixed'));

    function hide() {
        Cookies.set('rootLayoutFixed', 'true');

        setHidden(true);
    }

    function show() {
        Cookies.remove('rootLayoutFixed');

        setHidden(false);
    }

    const config = useMemo(
        () => ({
            controlsHidden: hidden,
            hideControls: hide,
            showControls: show,
        }),
        [hidden, hide, show]
    );

    return (
        <RootLayoutControlsContext.Provider value={config}>
            {children}
        </RootLayoutControlsContext.Provider>
    );
};

export function useRootLayoutContext() {
    return useContext(RootLayoutControlsContext);
}

export const Layout: FC<Props> = ({ children, headerProps }) => (
    <RootLayoutControlsProvider>
        <div style={{ height: '45px' }}>
            <Header {...headerProps} />
        </div>

        <div className={styles.root}>
            <Sidebar />
            <main className={styles.main}>
                <ObjectiveHeader {...headerProps} />
                {children}
            </main>
        </div>
    </RootLayoutControlsProvider>
);
