import { FC, useEffect } from 'react';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { notification } from 'antd';

interface Props {
    title: string;
    text: string;
}

const ConfirmBase: FC<Props> = ({ title, text }) => {
    const modalApi = useModal();
    const [api, contextHolder] = notification.useNotification();

    useEffect(() => {
        if (modalApi.visible) {
            api.success({
                message: title,
                description: text,
                duration: 0,
                onClose() {
                    modalApi.hide();
                },
            });
        }
    }, [modalApi.visible]);

    return contextHolder;
};
export const Confirm = NiceModal.create(ConfirmBase);
