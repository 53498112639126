import { FC } from 'react';
import { Position, NodeProps, Handle } from 'reactflow';

import { Wbs } from 'src/api/okr/wbs/wbs.types';

import { OkrCard } from '../okr-card/okr-card';

interface Props {
    hasChildren: boolean;
    onClickArrow: (id: string) => void;
    objective: Wbs;
    hierarchicalNum: string;
}

export const CustomNode: FC<NodeProps<Props>> = ({
    data,
    targetPosition = Position.Top,
    sourcePosition = Position.Bottom,
}) => (
    <>
        <Handle type="target" position={targetPosition} />
        <OkrCard
            onClickArrow={data.onClickArrow}
            wbs={data.objective}
            hierarchicalNum={data.hierarchicalNum}
            hasChildren={data.hasChildren}
        />
        <Handle type="source" position={sourcePosition} />
    </>
);
