import { getProgress } from 'src/utils/get-progress';

import { TreeListNode } from './create-okr-list-data';

export enum ColumnValues {
    Title = 'Наименование Objective',
    Source = 'Исходная',
    Current = 'Текущая',
    Planned = 'Плановая',
    View = 'Вид',
    Progress = 'Прогресс',
    Deadline = 'Дедлайн',
    Assignee = 'Исполнитель',
}

export const sortFunctions = {
    [ColumnValues.View]: (nodes) =>
        nodes.sort(({ objective: objective1 }, { objective: objective2 }) =>
            objective1.title > objective2.title ? -1 : 1
        ),

    [ColumnValues.Deadline]: (nodes) =>
        nodes.sort(({ objective: objective1 }, { objective: objective2 }) =>
            new Date(objective1.dateDeadline).getTime() >
            new Date(objective2.dateDeadline).getTime()
                ? -1
                : 1
        ),

    [ColumnValues.Assignee]: (nodes) =>
        nodes.sort(({ objective: objective1 }, { objective: objective2 }) => {
            const firstAssignedUserA = objective1.assignedUsers?.[0]?.personName;
            const firstAssignedUserB = objective2.assignedUsers?.[0]?.personName;

            return firstAssignedUserA > firstAssignedUserB ? -1 : 1;
        }),

    [ColumnValues.Progress]: (nodes) =>
        nodes.sort(({ objective: objective1 }, { objective: objective2 }) => {
            const progressA = getProgress(
                objective1.progressStartValue,
                objective1.progressFactValue,
                objective1.progressEndValue
            );

            const progressB = getProgress(
                objective2.progressStartValue,
                objective2.progressFactValue,
                objective2.progressEndValue
            );

            return progressA > progressB ? -1 : 1;
        }),
} satisfies Record<ColumnValues, (nodes: TreeListNode[]) => TreeListNode[]>;
