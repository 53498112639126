import { useMutation, UseMutationOptions } from '@tanstack/react-query';

import { putObjective } from 'src/api/okr/objective/objective.requests';
import { Objective, PutObjectivePayload } from 'src/api/okr/objective/objective.types';

export const usePutObjective = (
    config: UseMutationOptions<Objective, unknown, PutObjectivePayload> = {}
) =>
    useMutation<Objective, unknown, PutObjectivePayload>({
        mutationFn: (data) => putObjective(data),
        ...config,
    });
