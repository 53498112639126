import { useEffect, useMemo, useState } from 'react';
import ReactFlow, { Background, Edge, Node, ReactFlowProvider, useReactFlow } from 'reactflow';
import { Link } from 'react-router-dom';
import { CircularProgress } from '@mui/material';

import { Layout } from 'src/layout/layout';
import { generateEdges } from 'src/pages/okr-tree/generate-edges';
import { createTreeLayout } from 'src/pages/okr-tree/create-tree-layout';
import { useGetObjectiveHierarchy } from 'src/hooks/use-get-objective-hierarchy';
import { EmptyResult } from 'src/components/empty-result/empty-result';
import { locations } from 'src/constants/routes';
import { sortObjectsByPriority } from 'src/utils/sort-tree-by-priority';
import { SortPanel } from 'src/components/sort-panel/sort-panel';

import { Objective } from '../../api/okr/objective/objective.types';
import { ZoomControls } from '../../components/zoom-controls/zoom-controls';

import { CustomNode } from './okr-tree-custom-node/okr-tree-custom-node';
import styles from './okr-tree.module.css';

import 'reactflow/dist/style.css';

const NODE_TYPES = { custom: CustomNode };
const HEADING = 'OKR';

function removeChildrenNodes(objectives: Objective[], hiddenParentIds: string[]): Objective[] {
    return objectives.map((objective) => ({
        ...objective,
        children:
            hiddenParentIds.indexOf(objective.id) > -1
                ? []
                : removeChildrenNodes(objective.children, hiddenParentIds),
        hasChildren: objective.children.length > 0,
    }));
}

let hiddenParentNodeIds: string[] = [];

const OkrTreePageContent = () => {
    const { data, isLoading, isRefetching, isSuccess } = useGetObjectiveHierarchy();
    const { zoomIn, zoomOut } = useReactFlow();
    const [nodes, setNodes] = useState<Node[]>([]);
    const [edges, setEdges] = useState<Edge[]>([]);

    const sorterdTree = useMemo(() => {
        if (data) {
            return sortObjectsByPriority(data);
        }

        return [];
    }, [data]);

    function onClickArrow(id: string) {
        const nodeIdIndex = hiddenParentNodeIds.indexOf(id);

        if (nodeIdIndex === -1) {
            setNodes(
                createTreeLayout(
                    removeChildrenNodes(sorterdTree!, [...hiddenParentNodeIds, id]),
                    undefined,
                    null,
                    onClickArrow
                )
            );
            setEdges(
                generateEdges(removeChildrenNodes(sorterdTree!, [...hiddenParentNodeIds, id]))
            );

            hiddenParentNodeIds = [...hiddenParentNodeIds, id];
        } else {
            setNodes(
                createTreeLayout(
                    removeChildrenNodes(
                        sorterdTree!,
                        hiddenParentNodeIds.filter(
                            (hiddenParentNodeId) => hiddenParentNodeId !== id
                        )
                    ),
                    undefined,
                    null,
                    onClickArrow
                )
            );
            setEdges(
                generateEdges(
                    removeChildrenNodes(
                        sorterdTree!,
                        hiddenParentNodeIds.filter(
                            (hiddenParentNodeId) => hiddenParentNodeId !== id
                        )
                    )
                )
            );

            hiddenParentNodeIds = hiddenParentNodeIds.filter(
                (prevHiddenParentNodeId) => prevHiddenParentNodeId !== id
            );
        }
    }

    const onClickZoomIn = () => {
        zoomIn();
    };

    const onClickZoomOut = () => {
        zoomOut();
    };

    useEffect(() => {
        if (isSuccess) {
            setEdges(generateEdges(removeChildrenNodes(sorterdTree, [])));
            setNodes(
                createTreeLayout(
                    removeChildrenNodes(sorterdTree, []),
                    undefined,
                    null,
                    onClickArrow
                )
            );
        }
    }, [isSuccess, isRefetching]);

    return (
        <Layout
            headerProps={{
                title: 'Древо целей',
                showTabs: true,
                showNotifications: true,
                showActions: true,
            }}
        >
            {(isLoading || isRefetching) && (
                <div className={styles['spinner-container']}>
                    <CircularProgress size={100} />
                </div>
            )}

            {isSuccess && sorterdTree.length === 0 && (
                <EmptyResult
                    title={HEADING}
                    action={{
                        component: Link,
                        to: locations.createOKR,
                        children: `Создать ${HEADING}`,
                    }}
                />
            )}

            {isSuccess && !isRefetching && (
                <div style={{ width: '100%', height: '100%', position: 'relative' }}>
                    <ReactFlow
                        nodes={nodes}
                        edges={edges}
                        nodeTypes={NODE_TYPES}
                        fitView
                        minZoom={0.1}
                    >
                        <Background color="#F8FAFC" className={styles.background} />
                        <SortPanel />
                        <ZoomControls
                            onClickZoomOut={onClickZoomOut}
                            onClickZoomIn={onClickZoomIn}
                        />
                    </ReactFlow>
                </div>
            )}
        </Layout>
    );
};
export const OkrTreePage = () => (
    <ReactFlowProvider>
        <OkrTreePageContent />
    </ReactFlowProvider>
);
