import { Link, useLocation, useParams } from 'react-router-dom';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { ClusterOutlined, UnorderedListOutlined, BuildOutlined } from '@ant-design/icons';

import { locations } from 'src/constants/routes';

import styles from './header-tabs.module.css';

const MY_HEADER_TABS = [
    { id: '1', text: 'Древо', link: locations.okr, icon: <ClusterOutlined /> },
    { id: '2', text: 'Список', link: locations.okrList, icon: <UnorderedListOutlined /> },
];

const HEADER_TABS = [
    { id: '1', text: 'Древо', link: locations.wbsTree, icon: <ClusterOutlined /> },
    { id: '2', text: 'Список', link: locations.wbsList, icon: <UnorderedListOutlined /> },
    { id: '3', text: 'Ганта', link: locations.wbsList, icon: <BuildOutlined /> },
];

export const HeaderTabs = () => {
    const { pathname } = useLocation();
    const params = useParams();

    const paramsId = params?.id ?? '';

    const tabs = pathname.includes('okr') ? MY_HEADER_TABS : HEADER_TABS;

    if (pathname.includes('/wbs')) {
        return (
            <div className={styles.container}>
                <Tabs value={pathname} className={styles['tabs-container']}>
                    <Tab
                        label="Древо"
                        value={locations.wbsTree.replace(':id', paramsId)}
                        component={Link}
                        to={locations.wbsTree.replace(':id', paramsId)}
                        icon={<ClusterOutlined />}
                    />
                    <Tab
                        label="Список"
                        value={locations.wbsList.replace(':id', paramsId)}
                        component={Link}
                        to={locations.wbsList.replace(':id', paramsId)}
                        icon={<UnorderedListOutlined />}
                    />
                    <Tab
                        label="Ганта"
                        value={locations.wbsGanta.replace(':id', paramsId)}
                        component={Link}
                        to={locations.wbsGanta.replace(':id', paramsId)}
                        icon={<BuildOutlined />}
                    />
                </Tabs>
            </div>
        );
    }

    return (
        <div className={styles.container}>
            <Tabs value={pathname} className={styles['tabs-container']}>
                {tabs.map(({ id, text, link, icon }) => (
                    <Tab
                        key={id}
                        label={text}
                        value={link}
                        component={Link}
                        to={link}
                        icon={icon}
                    />
                ))}
            </Tabs>
        </div>
    );
};
