import { IconProps } from './types';

export const TimeIcon = ({
    width = 20,
    height = 20,
    fill = '#6323ED',
    className = '',
}: IconProps) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 20 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
    >
        <path
            d="M9.9915 2.16797C5.3915 2.16797 1.6665 5.9013 1.6665 10.5013C1.6665 15.1013 5.3915 18.8346 9.9915 18.8346C14.5998 18.8346 18.3332 15.1013 18.3332 10.5013C18.3332 5.9013 14.5998 2.16797 9.9915 2.16797ZM9.99984 17.168C6.3165 17.168 3.33317 14.1846 3.33317 10.5013C3.33317 6.81797 6.3165 3.83464 9.99984 3.83464C13.6832 3.83464 16.6665 6.81797 16.6665 10.5013C16.6665 14.1846 13.6832 17.168 9.99984 17.168ZM10.4165 6.33464H9.1665V11.3346L13.5415 13.9596L14.1665 12.9346L10.4165 10.7096V6.33464Z"
            fill={fill}
        />
    </svg>
);
