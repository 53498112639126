import { Edge } from 'reactflow';

import { Objective } from 'src/api/okr/objective/objective.types';

export const generateEdges = (nodes: Objective[]): Edge[] => {
    const edges: Edge[] = [];

    const addEdges = (node: Objective, parentId: string) => {
        node.children.forEach((child) => {
            edges.push({
                id: `e${parentId}-${child.id}`,
                source: parentId,
                target: child.id,
                type: 'smoothstep',
            });
            addEdges(child, child.id);
        });
    };

    nodes.forEach((node) => {
        addEdges(node, node.id);
    });

    return edges;
};
