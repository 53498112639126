import { Node } from 'reactflow';
import { hierarchy, HierarchyNode, HierarchyPointNode, tree } from 'd3-hierarchy';

import { createHierarchicalNum } from 'src/utils/create-hierarchical-num';
import { Wbs } from 'src/api/okr/wbs/wbs.types';

const NODE_WIDTH = 400;
const NODE_HEIGHT = 350;

const HORIZONTAL_GAP = 100;
const VERTICAL_GAP = 80;

const WIDTH_WITH_GAP = NODE_WIDTH + HORIZONTAL_GAP;
const HEIGHT_WITH_GAP = NODE_HEIGHT + VERTICAL_GAP;

const createRoot = (nodes: Wbs[]) => {
    const node = hierarchy({ children: nodes } as Wbs);

    const treeLayout = tree<Wbs>().nodeSize([WIDTH_WITH_GAP, HEIGHT_WITH_GAP]);

    const width = node.height * WIDTH_WITH_GAP;
    const height = node.descendants().length * HEIGHT_WITH_GAP;

    treeLayout.size([height, width]);

    treeLayout(node);

    node.descendants().forEach((n) => {
        const temp = n.x;

        n.x = n.y;
        n.y = temp;

        if (n.depth === 2) {
            const children = n.children || [];

            const thirdDepthLayout = tree<Wbs>().nodeSize([NODE_HEIGHT, NODE_WIDTH]);

            const tempNode = hierarchy(n.data);

            thirdDepthLayout(tempNode);

            children.forEach((child, i) => {
                const childrenHeight = children.length * HEIGHT_WITH_GAP;

                child.y = n.x! + WIDTH_WITH_GAP;
                child.x = n.y! - childrenHeight / 2 + NODE_HEIGHT * (i || 1);
            });
        }
    });

    return node;
};

export const createTreeLayout = (
    nodes: Wbs[],
    rootD3Node?: HierarchyNode<Wbs>,
    parentId?: string | null,
    onClickArrow?: (id: string) => void
): Node[] => {
    const rootNode = rootD3Node ?? createRoot(nodes);

    // @ts-ignore
    return nodes.flatMap((node, index) => {
        const hierarchicalNum = createHierarchicalNum(parentId, index);
        const d3Node = rootNode
            .descendants()
            .find((d) => d.data === node) as HierarchyPointNode<Wbs>;

        const nextNode = {
            id: node.id,
            type: 'custom',
            sourcePosition: 'right',
            targetPosition: 'left',
            data: {
                objective: node,
                hierarchicalNum,
                onClickArrow,
                hasChildren: node.children.length > 0,
            },
            position: d3Node
                ? {
                      x: d3Node.x + HORIZONTAL_GAP / 2,
                      y: d3Node.y / 2,
                  }
                : { x: 0, y: 0 },
            children: node.children,
        };

        const children = createTreeLayout(node.children, rootNode, hierarchicalNum, onClickArrow);

        return [nextNode, ...children];
    });
};
