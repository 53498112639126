export const locations = {
    index: '/',
    main: '/',
    okr: '/okr/graph',
    okrList: '/okr/list',
    list: '/tree/list',
    tree: '/tree/graph',
    profile: '/profile',
    users: '/users',
    objective: '/objective/:id',
    objectives: '/objectives',
    createWBS: '/create-wbs',
    wbs: '/wbs/:id',
    wbsTree: '/wbs/:id/tree',
    wbsList: '/wbs/:id/list',
    wbsGanta: '/wbs/:id/ganta',
    createOKR: '/objective/create-okr',
    createObjective: '/objective/:id/create-objective',
    createObjectiveWithoutParent: '/objective/create-objective',
    createKeyResult: '/objective/:id/create-key-result',
    editKeyResult: '/objective/:id/edit-key-result',
    editObjective: '/objective/:id/edit-objective',
} as const;
