import { Objective } from 'src/api/okr/objective/objective.types';

export function getNodeNestedLevel(id: string, objectives: Objective[], level = 0): number {
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < objectives.length; i++) {
        const node = objectives[i];

        if (node.id === id) {
            return level;
        }

        if (node.children) {
            const foundNode = getNodeNestedLevel(id, node.children, level + 1);
            if (foundNode) {
                return foundNode;
            }
        }
    }

    return 0;
}
