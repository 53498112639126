import { UseMutationOptions, useMutation } from '@tanstack/react-query';

import { Objective } from 'src/api/okr/objective/objective.types';
import { getWbsById, updateWbs } from 'src/api/okr/wbs/wbs.request';
import { CreateWbsPayload } from 'src/api/okr/wbs/wbs.types';
import { queryClient } from 'src/lib/query-client';

export const useUpdateWbs = (
    config: UseMutationOptions<Objective, unknown, CreateWbsPayload> = {}
) =>
    useMutation<Objective, unknown, CreateWbsPayload>({
        mutationFn: (data) => updateWbs(data),
        onSuccess: async () => {
            await queryClient.invalidateQueries({ queryKey: [getWbsById.name] });
        },
        ...config,
    });
