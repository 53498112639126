import { FC, ReactNode } from 'react';
import { Typography } from '@mui/material';

import styles from './objective-block.module.css';

interface Props {
    title: string;
    children: ReactNode;
}
export const ObjectiveBlock: FC<Props> = ({ title, children }) => (
    <div className={styles.objective}>
        <Typography className={styles.title}>{title}</Typography>

        <div className={styles.field}>{children} </div>
    </div>
);
