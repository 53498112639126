export const getProgress = (start: number, fact: number, plan: number) => {
    if (start === 0 && fact === 0 && plan === 0) {
        return 0;
    }

    const calculation = (Number(fact) - Number(start)) / (Number(plan) - Number(start));

    const result = Math.round(
        (Number.isNaN(calculation) || !Number.isFinite(calculation) ? 0 : calculation) * 100
    );

    if (result > 100) {
        return 100;
    }

    return result;
};

type Color = 'red' | 'orange' | 'blue' | 'green';

export const getProgressColor = (value: number): Color => {
    if (value <= 0 || value <= 25) return 'red';
    if (value <= 25 || value <= 50) return 'orange';
    if (value <= 50 || value <= 75) return 'blue';

    return 'green';
};
