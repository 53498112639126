import { IconProps } from './types';

export const PercentIcon = ({
    width = 20,
    height = 20,
    fill = '#6323ED',
    className = '',
}: IconProps) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 20 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
    >
        <path
            d="M6.25016 3.83203C4.64183 3.83203 3.3335 5.14036 3.3335 6.7487C3.3335 8.35703 4.64183 9.66536 6.25016 9.66536C7.8585 9.66536 9.16683 8.35703 9.16683 6.7487C9.16683 5.14036 7.8585 3.83203 6.25016 3.83203ZM6.25016 7.9987C5.5585 7.9987 5.00016 7.44036 5.00016 6.7487C5.00016 6.05703 5.5585 5.4987 6.25016 5.4987C6.94183 5.4987 7.50016 6.05703 7.50016 6.7487C7.50016 7.44036 6.94183 7.9987 6.25016 7.9987ZM13.7502 11.332C12.1418 11.332 10.8335 12.6404 10.8335 14.2487C10.8335 15.857 12.1418 17.1654 13.7502 17.1654C15.3585 17.1654 16.6668 15.857 16.6668 14.2487C16.6668 12.6404 15.3585 11.332 13.7502 11.332ZM13.7502 15.4987C13.0585 15.4987 12.5002 14.9404 12.5002 14.2487C12.5002 13.557 13.0585 12.9987 13.7502 12.9987C14.4418 12.9987 15.0002 13.557 15.0002 14.2487C15.0002 14.9404 14.4418 15.4987 13.7502 15.4987ZM4.5085 17.1654L3.3335 15.9904L15.4918 3.83203L16.6668 5.00703L4.5085 17.1654Z"
            fill={fill}
        />
    </svg>
);
