export const createHierarchicalNum = (parentId: string | null | undefined, index: number) => {
    if (parentId === undefined) {
        return null;
    }

    if (parentId === null) {
        return `${index + 1}`;
    }

    return `${parentId}.${index}`;
};
