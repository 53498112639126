import { FC, useEffect, useRef, useState } from 'react';
import Tooltip from '@mui/material/Tooltip';

import styles from './title-tooltip.module.css';

interface Props {
    title: string;
    lineClamp: number;
}

export const TitleTooltip: FC<Props> = ({ title, lineClamp }) => {
    const titleRef = useRef<HTMLSpanElement>(null);
    const [showTooltip, setShowTooltip] = useState<boolean>(false);

    useEffect(() => {
        if (!titleRef.current) return;

        if (lineClamp === 1) {
            setShowTooltip(titleRef.current.clientWidth === titleRef.current.scrollWidth);
        } else {
            setShowTooltip(titleRef.current.clientHeight === titleRef.current.scrollHeight);
        }
    }, []);

    return (
        <Tooltip title={title} placement="right-end" arrow disableHoverListener={showTooltip}>
            <span
                ref={titleRef}
                style={{
                    display: lineClamp > 1 ? '-webkit-box' : 'inline',
                    lineClamp,
                    WebkitLineClamp: lineClamp,
                }}
                className={styles['tooltip-text']}
            >
                {title}
            </span>
        </Tooltip>
    );
};
