import {
    Button,
    Popover,
    Box,
    Typography,
    Divider,
    FormControl,
    Autocomplete,
    TextField,
    MenuItem,
    Checkbox,
    ListItemText,
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import { DatePicker } from '@mui/x-date-pickers';
import { Controller, useForm } from 'react-hook-form';
import { pickBy, isEmpty } from 'lodash';
import { FC, useState } from 'react';
import NiceModal from '@ebay/nice-modal-react';
import CancelIcon from '@mui/icons-material/Cancel';
import { FilterOutlined } from '@ant-design/icons';

import { Filters } from 'src/api/okr/objective/filter/filter.types';
import { getObjectiveHierarchy } from 'src/api/okr/objective/objective.requests';
import { useGetUsersByFilter } from 'src/hooks/use-get-users-by-filter';
import { usePostFilter } from 'src/hooks/use-post-filter';
import { useRootLayoutContext } from 'src/layout/layout';
import { queryClient } from 'src/lib/query-client';
import { TaskForm } from 'src/types/task-form';
import { Confirm } from 'src/widgets/confirm/confirm';
import { HeaderTabs } from 'src/layout/header/header-tabs/header-tabs';
import { useGetObjectiveHierarchy } from 'src/hooks/use-get-objective-hierarchy';

import styles from './objective-header.module.css';

const handleError = () => {
    NiceModal.show(Confirm, {
        icon: <CancelIcon color="error" />,
        title: 'Что-то пошло не так!',
        text: 'Возможно, возникли технические трудности',
        actions: {
            accept: {
                children: 'Вернуться назад',
                onClick: () => NiceModal.hide(Confirm),
            },
        },
    });
};

interface Props {
    showTabs?: boolean;
    showActions?: boolean;
}

export const ObjectiveHeader: FC<Props> = ({ showTabs, showActions }) => {
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    const [assigneeInput, setAssigneeInput] = useState('');

    const { refetch } = useGetObjectiveHierarchy();
    const { controlsHidden } = useRootLayoutContext();

    const { data: assignees = [], isLoading } = useGetUsersByFilter(assigneeInput);

    const { mutate } = usePostFilter({
        async onSuccess(result) {
            queryClient.setQueryData([getObjectiveHierarchy.name], () => result);
        },

        onError: handleError,
    });

    const { control, getValues, reset, watch } = useForm<TaskForm>({
        defaultValues: {},
    });

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
        reset();
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const handleSubmit = () => {
        const { assignedUsers, dateStart, dateDeadline } = getValues();

        const payload: Filters = [];

        if (assignedUsers.length) {
            payload.push({
                fieldName: 'AssignedUserId',
                stringArrayValue: assignedUsers
                    .filter((person) => person !== null)
                    .map((user) => user.id),
            });
        }

        const dateFilter = pickBy(
            {
                StartDate: dateStart,
                EndDate: dateDeadline,
            },
            (value) => value !== null && value !== undefined
        );

        if (!isEmpty(dateFilter)) {
            payload.push({
                fieldName: 'DateDeadline',
                ...dateFilter,
            });
        }

        if (!payload.length) {
            refetch();
        } else {
            mutate(payload);
        }

        handleClose();
    };

    const handleReset = () => {
        refetch();
        handleClose();
    };

    if (controlsHidden) {
        return null;
    }

    const dateStart = watch('dateStart');
    const dateDeadline = watch('dateDeadline');

    return (
        <div className={styles.main}>
            <div>{showTabs && <HeaderTabs />}</div>
            {showActions && (
                <div className={styles['buttons-container']}>
                    <Button startIcon={<SearchIcon />} className={styles.button}>
                        Поиск
                    </Button>
                    <Button
                        startIcon={<FilterOutlined width={14} height={14} />}
                        className={styles.button}
                        onClick={handleClick}
                    >
                        Фильтры
                    </Button>
                    <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        sx={{
                            boxShadow:
                                '0 0 1px 0 rgba(0, 0, 0, 0.04), 0 2px 6px 0 rgba(0, 0, 0, 0.04), 0 16px 24px 0 rgba(0, 0, 0, 0.06)',
                        }}
                    >
                        <Box sx={{ width: '768px' }}>
                            <Box>
                                <Box py="6px" px={2}>
                                    <Typography className={styles.title}>Фильтровать</Typography>
                                </Box>
                                <Divider />
                                <div className={styles.fields}>
                                    <div className={styles.field}>
                                        <Typography className={styles['field-title']}>
                                            Ответственный:
                                        </Typography>

                                        <div className={styles.row}>
                                            <Controller
                                                name="assignedUsers"
                                                control={control}
                                                // @ts-ignore
                                                defaultValue={[]}
                                                render={({ field }) => (
                                                    <FormControl
                                                        className={styles['additional-field']}
                                                    >
                                                        <Autocomplete
                                                            {...field}
                                                            id={field.name}
                                                            multiple
                                                            disableCloseOnSelect
                                                            loading={isLoading}
                                                            size="small"
                                                            options={assignees}
                                                            className={styles['responsible-select']}
                                                            inputValue={assigneeInput}
                                                            noOptionsText="Введите ответственного"
                                                            onInputChange={(
                                                                event,
                                                                value,
                                                                reason
                                                            ) => {
                                                                if (reason !== 'reset') {
                                                                    setAssigneeInput(value);
                                                                }
                                                            }}
                                                            onChange={(event, value) =>
                                                                field.onChange(value)
                                                            }
                                                            isOptionEqualToValue={(option, value) =>
                                                                option?.id === value?.id
                                                            }
                                                            getOptionLabel={(name) =>
                                                                name?.personName ?? ''
                                                            }
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    placeholder="Выберите ответственного"
                                                                />
                                                            )}
                                                            ChipProps={{
                                                                deleteIcon: <ClearIcon />,
                                                            }}
                                                            clearIcon={<ClearIcon />}
                                                            style={{ padding: '2px 0' }}
                                                            renderOption={(props, option) => (
                                                                <MenuItem
                                                                    {...props}
                                                                    className={styles.option}
                                                                >
                                                                    <Checkbox
                                                                        name={option?.personName}
                                                                        checked={field.value.some(
                                                                            (user) =>
                                                                                user?.id ===
                                                                                option?.id
                                                                        )}
                                                                    />
                                                                    <ListItemText>
                                                                        {option?.personName}
                                                                    </ListItemText>
                                                                </MenuItem>
                                                            )}
                                                        />
                                                    </FormControl>
                                                )}
                                            />
                                        </div>
                                    </div>
                                    <div className={styles.field}>
                                        <Typography className={styles['field-title']}>
                                            Дедлайн:
                                        </Typography>

                                        <div className={styles.row}>
                                            <Controller
                                                name="dateStart"
                                                control={control}
                                                // @ts-ignore
                                                defaultValue={null}
                                                render={({ field }) => (
                                                    <FormControl
                                                        className={styles['additional-field']}
                                                    >
                                                        <DatePicker
                                                            {...field}
                                                            maxDate={dateDeadline}
                                                            slotProps={{
                                                                textField: {
                                                                    id: field.name,
                                                                    placeholder: 'Выберите дату',
                                                                },
                                                            }}
                                                            className={styles.datepicker}
                                                        />
                                                    </FormControl>
                                                )}
                                            />

                                            <Controller
                                                name="dateDeadline"
                                                control={control}
                                                // @ts-ignore
                                                defaultValue={null}
                                                render={({ field }) => (
                                                    <FormControl
                                                        className={styles['additional-field']}
                                                    >
                                                        <DatePicker
                                                            {...field}
                                                            minDate={dateStart}
                                                            slotProps={{
                                                                textField: {
                                                                    id: field.name,
                                                                    placeholder: 'Выберите дату',
                                                                },
                                                            }}
                                                            className={styles.datepicker}
                                                        />
                                                    </FormControl>
                                                )}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </Box>
                            <div className={styles.footer}>
                                <div>
                                    <Button className={styles.clear} onClick={handleReset}>
                                        Очистить все фильтры
                                    </Button>
                                </div>
                                <div className={styles.actions}>
                                    <Button className={styles.cancel} onClick={handleClose}>
                                        Отмена
                                    </Button>
                                    <Button
                                        className={styles.create}
                                        type="submit"
                                        onClick={handleSubmit}
                                    >
                                        Применить
                                    </Button>
                                </div>
                            </div>
                        </Box>
                    </Popover>
                </div>
            )}
        </div>
    );
};
