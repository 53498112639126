import { UseMutationOptions, useMutation } from '@tanstack/react-query';

import { deleteKeyResult } from 'src/api/okr/key-result/key-result.requests';
import { KeyResult } from 'src/api/okr/key-result/key-result.types';

type Config = Partial<UseMutationOptions<KeyResult, unknown, string>>;

export const useDeleteKeyResult = (config?: Config) =>
    useMutation<KeyResult, unknown, string>({
        mutationFn: (id: string) => deleteKeyResult(id),
        ...config,
    });
