import { useQuery } from '@tanstack/react-query';
import { useLocation } from 'react-router-dom';

import { getObjectiveHierarchy } from 'src/api/okr/objective/objective.requests';
import { Objective } from 'src/api/okr/objective/objective.types';

export const useGetObjectiveHierarchy = () => {
    const { pathname } = useLocation();
    const isOldHierarchy = pathname.includes('okr');

    return useQuery<Objective[]>({
        queryKey: [getObjectiveHierarchy.name, isOldHierarchy],
        queryFn: () => getObjectiveHierarchy(isOldHierarchy),
        refetchOnMount: true,
        refetchOnWindowFocus: true,
    });
};
