import { FC } from 'react';
import Tooltip from '@mui/material/Tooltip';

import styles from './assigned-users-tooltip.module.css';

interface AssignedUser {
    id: string;
    personName: string;
}

interface Props {
    assignedUsers?: (AssignedUser | null)[];
}

const MAX_ASSIGNEES_TO_DISPLAY = 1;

export const AssignedUsersTooltip: FC<Props> = ({ assignedUsers = [] }) => {
    const assignees = assignedUsers
        .filter((person) => person !== null)
        .map((person) => person?.personName ?? '');

    if (assignees.length <= MAX_ASSIGNEES_TO_DISPLAY) {
        return assignees.slice(0, MAX_ASSIGNEES_TO_DISPLAY).join(', ');
    }

    const displayedAssignees = assignees.slice(0, MAX_ASSIGNEES_TO_DISPLAY).join(', ');

    return (
        <Tooltip title={assignees.join(', ')} placement="right-end" arrow>
            <span className={styles['tooltip-text']}>
                {displayedAssignees}
                <span>...</span>
            </span>
        </Tooltip>
    );
};
