import { FC } from 'react';
import {
    Typography,
    Accordion,
    AccordionDetails,
    AccordionSummary,
    IconButton,
} from '@mui/material';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import ExpandCircleDownOutlinedIcon from '@mui/icons-material/ExpandCircleDownOutlined';
import { Link } from 'react-router-dom';

import { SubtasksListItem } from 'src/components/subtasks-list-item/subtasks-list-item';
import { Objective } from 'src/api/okr/objective/objective.types';
import { KeyResult } from 'src/api/okr/key-result/key-result.types';

import styles from './objective-accordion.module.css';

interface Props {
    result: KeyResult | Objective;
    editLink: string;
}

export const ObjectiveAccordion: FC<Props> = ({ result, editLink }) => (
    <Accordion className={styles.item}>
        <AccordionSummary
            expandIcon={<ExpandCircleDownOutlinedIcon className={styles.check} />}
            aria-controls="panel1-content"
            id="panel1-header"
        >
            <SubtasksListItem result={result} />
            <IconButton component={Link} to={editLink} state={{ objective: result }}>
                <BorderColorOutlinedIcon className={styles.edit} />
            </IconButton>
        </AccordionSummary>

        <AccordionDetails className={styles.details}>
            <div className={styles['data-fields']}>
                <div className={styles['additional-field']}>
                    <Typography className={styles['field-title']}>Фактический результат</Typography>

                    <Typography className={styles.title}>{result.progressFactValue}</Typography>
                </div>

                <div className={styles['additional-field']}>
                    <Typography className={styles['field-title']}>Плановый результат</Typography>

                    <Typography className={styles.title}>{result.progressEndValue}</Typography>
                </div>
            </div>

            <div className={styles['additional-field']}>
                <Typography className={styles['field-title']}>Описание</Typography>

                <Typography className={styles.title}>{result.description}</Typography>
            </div>
        </AccordionDetails>
    </Accordion>
);
