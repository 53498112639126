import { useMutation, UseMutationOptions } from '@tanstack/react-query';

import { postKeyResult } from 'src/api/okr/key-result/key-result.requests';
import { KeyResult, PostKeyResultPayload } from 'src/api/okr/key-result/key-result.types';

export const usePostKeyResult = (
    config: UseMutationOptions<KeyResult, unknown, PostKeyResultPayload> = {}
) =>
    useMutation<KeyResult, unknown, PostKeyResultPayload>({
        mutationFn: (data) => postKeyResult(data),
        ...config,
    });
