import { FC, useMemo, useState } from 'react';
import { Button, Card, CardContent, CardHeader, IconButton, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import ArrowDropUpOutlinedIcon from '@mui/icons-material/ArrowDropUpOutlined';
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';
import { ClockCircleOutlined, EditOutlined, UserOutlined } from '@ant-design/icons';
import NiceModal from '@ebay/nice-modal-react';
import { Tooltip } from 'antd';

import { AssignedUsersTooltip } from 'src/components/assigned-users-tooltip/assigned-users-tooltip';
import { ProgressBar } from 'src/components/progress-bar/progress-bar';
import { ProgressUnit } from 'src/api/okr/objective/objective.types';
import { getProgress } from 'src/utils/get-progress';
import { formatDate } from 'src/utils/format-date';
import { TitleTooltip } from 'src/components/title-tooltip/title-tooltip';
import { ProgressValue } from 'src/components/progress-value/progress-value';
import { progressUnitsValues } from 'src/constants/progress-units-values';
import { Wbs } from 'src/api/okr/wbs/wbs.types';
import { WbsDrawer } from 'src/widgets/wbs-drawer/wbs-drawer';
import { ObjectiveDrawer } from 'src/widgets/objective-drawer/objective-drawer';

import styles from './okr-card.module.css';

interface Props {
    hasChildren: boolean;
    onClickArrow: (id: string) => void;
    wbs: Wbs;
    hierarchicalNum: string | null;
}

export const OkrCard: FC<Props> = ({ wbs, hierarchicalNum, onClickArrow }) => {
    const [openDrawer, setOpenDrawer] = useState(false);
    const [isEdit, setIsEdit] = useState(false);

    const objective = wbs?.task;

    const progressUnit = useMemo(
        () =>
            progressUnitsValues.find(
                (unit) => objective?.progressUnit && unit.id === objective?.progressUnit
            ),
        [progressUnitsValues, objective]
    );

    const toggleDrawer = (isEditMode = false) => {
        setOpenDrawer((prev) => !prev);
        setIsEdit(isEditMode);
    };

    const handeOnClickArrow = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        e.stopPropagation();

        onClickArrow(wbs?.id);
    };

    const handleEditCard = (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
        e.preventDefault();

        toggleDrawer(true);
    };

    const openObjectiveDrawer = (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation();

        // @ts-ignore
        NiceModal.show(ObjectiveDrawer, {
            objective,
            title: hierarchicalNum ? `Objective ${hierarchicalNum}` : 'OKR',
        });
    };

    const iconHeight = progressUnit?.id === ProgressUnit.Billion ? 11 : 20;
    const title =
        !!hierarchicalNum && hierarchicalNum.length < 5
            ? wbs?.title
            : `Инициатива ${hierarchicalNum}`;

    return (
        <Card
            className={styles.root}
            data-hierarchical={hierarchicalNum?.length === 1 ? 0 : hierarchicalNum?.charAt(2)}
            onClick={openObjectiveDrawer}
        >
            <Tooltip title={title}>
                <div className={styles.banner}>
                    <Typography className={styles['banner-title']}>{title}</Typography>
                    <div className={styles['banner-big-icon']}>
                        {!!hierarchicalNum && hierarchicalNum.length < 5 && progressUnit?.icon && (
                            <progressUnit.icon fill="white" height={iconHeight} />
                        )}
                    </div>
                </div>
            </Tooltip>

            <CardHeader
                title={<TitleTooltip title={objective?.title ?? ''} lineClamp={2} />}
                action={
                    <Button
                        className={styles['button-edit']}
                        aria-label="Редактировать"
                        onClick={handleEditCard}
                    >
                        <EditOutlined />
                    </Button>
                }
                className={styles.header}
            />

            <CardContent className={styles.main}>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                    }}
                >
                    {objective?.assignedUsers?.length && objective?.assignedUsers?.length > 0 && (
                        <div className={styles['card-content']}>
                            <Typography className={styles.title}>
                                <UserOutlined width={18} height={18} />
                            </Typography>
                            <Typography className={styles['card-info']}>
                                <AssignedUsersTooltip
                                    assignedUsers={objective?.assignedUsers ?? []}
                                />
                            </Typography>
                        </div>
                    )}

                    {objective?.dateDeadline && (
                        <div className={styles['card-content']}>
                            <Typography className={styles.title}>
                                <ClockCircleOutlined width={18} height={18} />
                            </Typography>
                            <Typography className={styles['deadline-text']}>
                                {objective?.dateDeadline && formatDate(objective.dateDeadline)}
                            </Typography>
                        </div>
                    )}
                </div>
                {!!hierarchicalNum && hierarchicalNum.length < 5 && (
                    <>
                        <div className={styles['bottom-block']}>
                            <div className={styles.progress}>
                                <ProgressBar
                                    progress={getProgress(
                                        objective?.progressStartValue ?? 0,
                                        objective?.progressFactValue ?? 0,
                                        objective?.progressEndValue ?? 0
                                    )}
                                />
                                {objective?.progressUnit === 0 ? (
                                    <Typography className={styles['progress-values']}>
                                        с {objective.progressStartValue} <TrendingFlatIcon />
                                        дo {objective.progressEndValue}
                                    </Typography>
                                ) : (
                                    <ProgressValue
                                        progressEndValue={objective?.progressEndValue}
                                        progressUnit={objective?.progressUnit}
                                        progressStartValue={objective?.progressStartValue}
                                    />
                                )}
                            </div>
                        </div>

                        <Button
                            onClick={(e) => {
                                e.stopPropagation();
                                toggleDrawer();
                            }}
                            endIcon={<AddIcon />}
                            className={styles.button}
                            fullWidth
                        >
                            Добавить
                        </Button>
                    </>
                )}
                {/* @ts-ignore */}
                {wbs?.hasChildren && (
                    <IconButton className={styles['bottom-icon']} onClick={handeOnClickArrow}>
                        {wbs.children.length > 0 ? (
                            <ArrowDropDownOutlinedIcon />
                        ) : (
                            <ArrowDropUpOutlinedIcon />
                        )}
                    </IconButton>
                )}
            </CardContent>
            <WbsDrawer
                open={openDrawer}
                toggle={toggleDrawer}
                id={wbs.id}
                isEdit={isEdit}
                title={isEdit ? wbs.title : ''}
                option={isEdit ? objective?.id : ''}
                wbs={wbs}
            />
        </Card>
    );
};
