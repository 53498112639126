import React, { FC } from 'react';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

import styles from './zoom-controls.module.css';

interface Props {
    onClickZoomOut: () => void;
    onClickZoomIn: () => void;
}

export const ZoomControls: FC<Props> = ({ onClickZoomOut, onClickZoomIn }) => {
    function handleOnClickZoomOut(e: React.MouseEvent<HTMLButtonElement>) {
        e.preventDefault();
        e.stopPropagation();

        onClickZoomOut();
    }

    function handleOnClickZoomIn(e: React.MouseEvent<HTMLButtonElement>) {
        e.preventDefault();
        e.stopPropagation();

        onClickZoomIn();
    }

    return (
        <div className={styles['zoom-controls']}>
            <button
                className={styles['zoom-controls__button']}
                onClick={handleOnClickZoomOut}
                aria-label="Zoom out"
                type="button"
            >
                <RemoveIcon />
            </button>
            <button
                className={styles['zoom-controls__button']}
                onClick={handleOnClickZoomIn}
                aria-label="Zoom in"
                type="button"
            >
                <AddIcon />
            </button>
        </div>
    );
};
