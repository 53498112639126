import { Navigate, useLocation, useNavigate, useParams } from 'react-router-dom';
import NiceModal from '@ebay/nice-modal-react';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import { locations } from 'src/constants/routes';
import { Layout } from 'src/layout/layout';
import { ObjectiveForm } from 'src/widgets/objective-form/objective-form';
import { queryClient } from 'src/lib/query-client';
import { getObjectiveById, getObjectiveHierarchy } from 'src/api/okr/objective/objective.requests';
import { Confirm } from 'src/widgets/confirm/confirm';
import { usePutKeyResult } from 'src/hooks/use-put-key-result';
import { KeyResult, PutKeyResultPayload } from 'src/api/okr/key-result/key-result.types';
import { useDeleteKeyResult } from 'src/hooks/use-delete-key-result';

import styles from './edit-key-result.page.module.css';

const handleError = () => {
    NiceModal.show(Confirm, {
        icon: <CancelIcon color="error" />,
        title: 'Что-то пошло не так!',
        text: 'Возможно, возникли технические трудности',
        actions: {
            accept: {
                children: 'Вернуться назад',
                onClick: () => NiceModal.hide(Confirm),
            },
        },
    });
};

const showSuccessModal = () =>
    NiceModal.show(Confirm, {
        icon: <CheckBoxIcon color="success" />,
        title: 'Изменения сохранены',
        text: 'Не забудьте проверить результат и продолжить работу',
        actions: {
            accept: {
                children: 'Продолжить',
                onClick: () => NiceModal.hide(Confirm),
            },
        },
    });

export const EditKeyResultPage = () => {
    const params = useParams();
    const navigate = useNavigate();
    const { state } = useLocation();
    const [modal, contextHolder] = Modal.useModal();

    const keyResult = state?.objective as KeyResult | undefined;

    const handleSuccess = async () => {
        await queryClient.invalidateQueries({ queryKey: [getObjectiveHierarchy.name] });

        await queryClient.invalidateQueries({
            queryKey: [getObjectiveById.name, params.id],
        });

        navigate(locations.objectives);

        showSuccessModal();
    };

    const updateMutation = usePutKeyResult({
        onSuccess: handleSuccess,
        onError: handleError,
    });

    const deleteMutation = useDeleteKeyResult({
        onSuccess: handleSuccess,
        onError: handleError,
    });

    if (!keyResult) {
        return <Navigate to={locations.tree} />;
    }

    const handleDelete = () =>
        modal.confirm({
            title: 'Вы уверены?',
            icon: <ExclamationCircleOutlined />,
            content: 'Внесенные данные не будут сохранены',
            okText: 'Продолжить',
            cancelText: 'Отмена',
            onOk: () => deleteMutation.mutate(keyResult.id),
        });

    return (
        <Layout>
            {contextHolder}
            <div className={styles.container}>
                <ObjectiveForm
                    title="Редактировать Key Result"
                    disableSubmit={updateMutation.isPending}
                    defaultValues={keyResult}
                    onCancel={() => navigate(-1)}
                    onDelete={handleDelete}
                    onSubmit={(data) => {
                        const assignedUsers = data.assignedUsers
                            .filter((person) => person !== null)
                            .map(({ id }) => id);
                        const payload = {
                            ...data,
                            assignedUsers,
                        } as unknown as PutKeyResultPayload;

                        updateMutation.mutate(payload);
                    }}
                />
            </div>
        </Layout>
    );
};
