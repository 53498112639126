import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { CircularProgress, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import KeyIcon from '@mui/icons-material/Key';
import LocationSearchingIcon from '@mui/icons-material/LocationSearching';

import { locations } from 'src/constants/routes';
import { Layout } from 'src/layout/layout';
import { ObjectiveBreadcrumbs } from 'src/pages/objective/objective-breadcrumbs/objective-breadcrumbs';
import { useGetObjective } from 'src/hooks/use-get-objective';

import { ObjectiveCard } from './objective-card/objective-card';
import { SubtasksList } from './subtasks-list/subtasks-list';
import styles from './objective-page.module.css';

export const ObjectivePage = () => {
    const { state, pathname } = useLocation();
    const { id } = useParams();
    const navigate = useNavigate();

    const {
        data: objective,
        isError,
        isSuccess,
        isLoading,
    } = useGetObjective(id as string, {
        initialData: state?.objective,
    });

    const BREADCRUMBS_VALUES = [
        { id: '1', label: 'Древо целей', link: locations.tree },
        { id: '2', label: 'Objective', link: pathname },
    ];

    const handleBackButtonClick = () => {
        navigate(-1);
    };

    if (isError) {
        return <h1>Error</h1>;
    }

    return (
        <Layout>
            {isLoading && (
                <div className={styles['spinner-container']}>
                    <CircularProgress size={100} />
                </div>
            )}

            {isSuccess && (
                <div className={styles.container}>
                    <ObjectiveBreadcrumbs data={BREADCRUMBS_VALUES} />

                    <Button className={styles['back-button']} onClick={handleBackButtonClick}>
                        <ArrowBackIcon className={styles['back-icon']} />
                        <Typography>Назад</Typography>
                    </Button>

                    <ObjectiveCard objective={objective} />

                    <SubtasksList
                        heading="Key Result"
                        title={objective?.title ?? ''}
                        results={objective?.keyResults ?? []}
                        icon={<KeyIcon className={styles.icon} />}
                        editLink={locations.editKeyResult.replace(':id', objective.id)}
                        link={locations.createKeyResult.replace(':id', objective.id)}
                    />

                    <SubtasksList
                        heading="Objective"
                        title={objective?.title ?? ''}
                        results={objective?.children ?? []}
                        icon={<LocationSearchingIcon className={styles.icon} />}
                        editLink={locations.editObjective.replace(':id', objective.id)}
                        link={locations.createObjective.replace(':id', objective.id)}
                    />
                </div>
            )}
        </Layout>
    );
};
