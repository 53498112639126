import { Link } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import { Table } from 'antd';
import { RightOutlined } from '@ant-design/icons';

import { Layout } from 'src/layout/layout';
import { EmptyResult } from 'src/components/empty-result/empty-result';
import { locations } from 'src/constants/routes';
import { useGetObjectiveHierarchy } from 'src/hooks/use-get-objective-hierarchy';
import { addNumberField } from 'src/shared/helpers/add-number-field';

import styles from './okr-list.module.css';
import { columns } from './columns';

const HEADING = 'OKR';

export const OkrListPage = () => {
    const { data: objectives = [], isLoading, isSuccess } = useGetObjectiveHierarchy();

    return (
        <Layout
            headerProps={{
                title: 'Список',
                showTabs: true,
                showNotifications: true,
                showActions: true,
            }}
        >
            {isLoading && <CircularProgress />}

            {isSuccess && objectives.length === 0 && (
                <EmptyResult
                    title={HEADING}
                    action={{
                        component: Link,
                        to: locations.createOKR,
                        children: `Создать ${HEADING}`,
                    }}
                />
            )}

            {isSuccess && (
                <div className={styles.container}>
                    <Table
                        // @ts-ignore
                        columns={columns}
                        dataSource={addNumberField(objectives)}
                        rowKey="id"
                        rowClassName={styles.row}
                        expandable={{
                            childrenColumnName: 'children',
                            defaultExpandAllRows: true,
                            indentSize: 0,
                            // eslint-disable-next-line react/no-unstable-nested-components
                            expandIcon: ({ expanded, onExpand, record }) => (
                                // eslint-disable-next-line jsx-a11y/no-static-element-interactions
                                <div
                                    onClick={(e) => onExpand(record, e)}
                                    className={styles['row-icon']}
                                    data-hierarchical={
                                        record.number?.length === 1 ? 0 : record?.number?.charAt(2)
                                    }
                                >
                                    {record.children.length > 0 && (
                                        <RightOutlined
                                            color="#1D1D1F"
                                            className={expanded ? styles['icon-rotate'] : ''}
                                        />
                                    )}
                                </div>
                            ),
                        }}
                    />
                </div>
            )}
        </Layout>
    );
};
