import { FC, ReactNode } from 'react';
import { Typography } from '@mui/material';

import styles from './objective-field.module.css';

interface Props {
    title: string;
    field?: string | number;
    children?: ReactNode;
}

export const ObjectiveField: FC<Props> = ({ title, field, children }) => (
    <div className={styles['info-row']}>
        <Typography className={styles.title}>{title}</Typography>

        {field && <Typography className={styles.field}>{field}</Typography>}

        {children && <div className={styles.field}>{children}</div>}
    </div>
);
