import { FC } from 'react';
import { Position, NodeProps, Handle } from 'reactflow';

import { Objective } from 'src/api/okr/objective/objective.types';
import { OkrCard } from 'src/pages/okr-tree/okr-card/okr-card';

interface Props {
    hasChildren: boolean;
    onClickArrow: (id: string) => void;
    objective: Objective;
    hierarchicalNum: string;
}

export const CustomNode: FC<NodeProps<Props>> = ({
    data,
    targetPosition = Position.Top,
    sourcePosition = Position.Bottom,
}) => (
    <>
        <Handle type="target" position={targetPosition} />
        <OkrCard
            onClickArrow={data.onClickArrow}
            objective={data.objective}
            hierarchicalNum={data.hierarchicalNum}
            hasChildren={data.hasChildren}
        />
        <Handle type="source" position={sourcePosition} />
    </>
);
