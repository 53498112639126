import { useEffect, useMemo } from 'react';
import {
    Button,
    Col,
    Drawer,
    Form,
    FormProps,
    Input,
    Popconfirm,
    Row,
    Select,
    Space,
    notification,
} from 'antd';
import { useParams } from 'react-router-dom';

import { usePostWbs } from 'src/hooks/use-post-wbs';
import { useGetObjectiveHierarchy } from 'src/hooks/use-get-objective-hierarchy';
import { useUpdateWbs } from 'src/hooks/use-update-wbs';
import { Wbs } from 'src/api/okr/wbs/wbs.types';
import { useGetWbsById } from 'src/hooks/use-get-wbs-by-id';

import styles from './wbs-drawer.module.css';

const { Option } = Select;

type FieldType = {
    title: string;
    taskId: string;
};

type WbsDrawerProps = {
    open: boolean;
    toggle: () => void;
    id?: string;
    isEdit?: boolean;
    title?: string;
    option?: string;
    wbs?: Wbs;
};

function findParentIdByChildId(data: Wbs[], childId?: string): string | null {
    // eslint-disable-next-line no-restricted-syntax
    for (const parent of data) {
        // eslint-disable-next-line no-restricted-syntax
        for (const child of parent.children) {
            if (child.id === childId) {
                return parent.id;
            }
            const result = findParentIdByChildId([child], childId);
            if (result) {
                return parent.id;
            }
        }
    }
    return null;
}

export const WbsDrawer = ({ open, toggle, id, isEdit, title, option, wbs }: WbsDrawerProps) => {
    const params = useParams();
    const [, contextHolder] = notification.useNotification();

    const { data: allWbs = [] } = useGetWbsById(params?.id);

    const { data: objectives = [] } = useGetObjectiveHierarchy();
    const { mutateAsync } = usePostWbs();
    const update = useUpdateWbs();

    const [form] = Form.useForm();

    const options = useMemo(
        () =>
            objectives.map((objective) => ({
                label: objective.title,
                value: objective.id,
            })),
        [objectives]
    );

    const onClose = (e?: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element>) => {
        e?.stopPropagation?.();
        e?.nativeEvent?.stopImmediatePropagation?.();

        toggle();
    };

    const onSubmit: FormProps<FieldType>['onFinish'] = async (data) => {
        try {
            if (isEdit) {
                const parentId = findParentIdByChildId(allWbs, id);
                await update.mutateAsync({
                    ...data,
                    creatorId: '00f3d9b3-c132-41d2-b4ac-419b625a2964',
                    parentId,
                    id: wbs?.id,
                    isDeleted: false,
                });
            } else {
                const taskId = data.taskId === '' ? null : data.taskId;

                await mutateAsync({
                    ...data,
                    creatorId: '00f3d9b3-c132-41d2-b4ac-419b625a2964',
                    parentId: id,
                    taskId,
                    isDeleted: false,
                });
            }
        } catch (err) {
            /* empty */
        } finally {
            onClose();
        }
    };

    const onDeleteWbs = async () => {
        try {
            if (isEdit && wbs?.id) {
                await update.mutateAsync({
                    title: title ?? null,
                    taskId: option ?? null,
                    creatorId: '00f3d9b3-c132-41d2-b4ac-419b625a2964',
                    parentId: id,
                    id: wbs?.id,
                    isDeleted: true,
                });
            }
        } catch (err) {
            /* empty */
        } finally {
            onClose();
        }
    };

    const filterOption = (
        input: string,
        config?: { label: string; value: string; children?: string }
    ) => (config?.children ?? '').toLowerCase().includes(input.toLowerCase());

    useEffect(() => {
        form.setFieldsValue({
            title: title ?? '',
            taskId: option ?? '',
        });
    }, [open]);

    return (
        <Drawer
            title={isEdit ? 'Редактировать WBS' : 'Создать WBS'}
            onClose={onClose}
            closeIcon={null}
            open={open}
            width={700}
            zIndex={1600}
            onClick={(e) => {
                e.stopPropagation();
                e.nativeEvent.stopImmediatePropagation();
            }}
            extra={
                <Space>
                    <Button onClick={onClose}>Отмена</Button>
                    {isEdit && (
                        <Popconfirm
                            title="Вы уверены?"
                            description="Внесенные данные не будут сохранены"
                            onConfirm={onDeleteWbs}
                            okText="Удалить"
                            cancelText="Отмена"
                        >
                            <Button danger>Удалить</Button>
                        </Popconfirm>
                    )}
                    <Button
                        type="primary"
                        htmlType="submit"
                        onClick={() => {
                            form.submit();
                        }}
                    >
                        Сохранить
                    </Button>
                </Space>
            }
        >
            {contextHolder}
            <Form layout="vertical" onFinish={onSubmit} form={form}>
                <h3 className={styles.title}>Основная информация</h3>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            name="title"
                            label="Наименование WBS"
                            rules={[{ required: true, message: 'Введите наименование WBS' }]}
                        >
                            <Input placeholder="Введите наименование WBS" allowClear />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item name="taskId" label="Objective">
                            <Select
                                placeholder="Выберите Objective"
                                allowClear
                                showSearch
                                filterOption={filterOption}
                                defaultValue={option}
                            >
                                {options.map((opt) => (
                                    <Option key={opt.value}>{opt.label}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Drawer>
    );
};
