import { Objective } from 'src/api/okr/objective/objective.types';

export const sortObjectsByPriority = (objects: Objective[]) => {
    const sortChildrenByPriority = (obj: Objective): void => {
        if (obj.children) {
            obj.children.sort((a, b) => b.priority - a.priority);
            obj.children.forEach((child) => sortChildrenByPriority(child));
        }
    };

    objects.sort((a, b) => b.priority - a.priority);
    objects.forEach((obj) => sortChildrenByPriority(obj));
    return objects;
};
