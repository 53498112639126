import { IconProps } from './types';

export const FullIcon = ({
    width = 20,
    height = 20,
    fill = '#1D1D1F',
    className = '',
}: IconProps) => (
    <svg width={width} height={height} className={className}>
        <path
            d="M18.9998 1.83464C18.9998 1.52522 18.8769 1.22847 18.6581 1.00968C18.4393 0.790885 18.1426 0.667969 17.8332 0.667969H11.9998C11.6904 0.667969 11.3937 0.790885 11.1749 1.00968C10.9561 1.22847 10.8332 1.52522 10.8332 1.83464C10.8332 2.14405 10.9561 2.4408 11.1749 2.65959C11.3937 2.87839 11.6904 3.0013 11.9998 3.0013H14.9982L11.1715 6.83964C11.0622 6.94809 10.9754 7.07713 10.9161 7.2193C10.8569 7.36146 10.8264 7.51395 10.8264 7.66797C10.8264 7.82198 10.8569 7.97447 10.9161 8.11664C10.9754 8.25881 11.0622 8.38785 11.1715 8.4963C11.28 8.60565 11.409 8.69244 11.5512 8.75167C11.6933 8.8109 11.8458 8.8414 11.9998 8.8414C12.1539 8.8414 12.3064 8.8109 12.4485 8.75167C12.5907 8.69244 12.7197 8.60565 12.8282 8.4963L16.6665 4.65797V7.66797C16.6665 7.97739 16.7894 8.27413 17.0082 8.49293C17.227 8.71172 17.5238 8.83463 17.8332 8.83463C18.1426 8.83463 18.4393 8.71172 18.6581 8.49293C18.8769 8.27413 18.9998 7.97739 18.9998 7.66797V1.83464Z"
            fill={fill}
        />
        <path
            d="M8.16158 11.5053C8.05313 11.3959 7.92409 11.3091 7.78192 11.2499C7.63976 11.1907 7.48727 11.1602 7.33325 11.1602C7.17924 11.1602 7.02675 11.1907 6.88458 11.2499C6.74241 11.3091 6.61338 11.3959 6.50492 11.5053L2.66659 15.3319V12.3336C2.66659 12.0242 2.54367 11.7274 2.32488 11.5086C2.10608 11.2898 1.80934 11.1669 1.49992 11.1669C1.1905 11.1669 0.893753 11.2898 0.674961 11.5086C0.456168 11.7274 0.333252 12.0242 0.333252 12.3336V18.1669C0.333252 18.4763 0.456168 18.7731 0.674961 18.9919C0.893753 19.2107 1.1905 19.3336 1.49992 19.3336H7.33325C7.64267 19.3336 7.93942 19.2107 8.15821 18.9919C8.377 18.7731 8.49992 18.4763 8.49992 18.1669C8.49992 17.8575 8.377 17.5608 8.15821 17.342C7.93942 17.1232 7.64267 17.0003 7.33325 17.0003H4.32325L8.16158 13.1619C8.27094 13.0535 8.35773 12.9244 8.41696 12.7823C8.47619 12.6401 8.50668 12.4876 8.50668 12.3336C8.50668 12.1796 8.47619 12.0271 8.41696 11.8849C8.35773 11.7427 8.27094 11.6137 8.16158 11.5053Z"
            fill={fill}
        />
    </svg>
);
