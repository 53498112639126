import { IconProps } from './types';

export const TengeIcon = ({
    width = 20,
    height = 20,
    fill = '#6323ED',
    className = '',
}: IconProps) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 20 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
    >
        <path
            d="M10.3571 6.61111V15.5H9.64286V6.61111V6.11111H9.14286H4.5V5.66667H15.5V6.11111H10.8571H10.3571V6.61111ZM4.5 3.5H15.5V3.94444H4.5V3.5Z"
            fill={fill}
            stroke={fill}
        />
    </svg>
);
