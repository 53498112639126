import { ConfigProvider } from 'antd';
import { Route, Routes } from 'react-router-dom';

import { locations } from 'src/constants/routes';
import { MainPage } from 'src/pages/main/main.page';
import { OkrTreePage } from 'src/pages/okr-tree/okr-tree.page';
import { OkrListPage } from 'src/pages/okr-list/okr-list.page';
import { ObjectivePage } from 'src/pages/objective/objective.page';
import { CreateObjectivePage } from 'src/pages/create-objective/create-objective.page';
import { EditObjectivePage } from 'src/pages/edit-objective/edit-objective.page';
import { EditKeyResultPage } from 'src/pages/edit-key-result/edit-key-result.page';
import { CreateKeyResultPage } from 'src/pages/create-key-result/create-key-result.page';
import { CreateOKRPage } from 'src/pages/create-okr/create-okr.page';
import { ObjectivesPage } from 'src/pages/objectives/objectives.page';
import { CreateWBS } from 'src/pages/create-wbs/create-wbs.page';
import { WbsTreePage } from 'src/pages/wbs/wbs.page';
import { WbsLisPage } from 'src/pages/wbs/wbs-list/okr-list.page';

import './app.module.css';

export const App = () => (
    <ConfigProvider
        theme={{
            cssVar: true,
            token: {
                colorPrimary: 'hsla(259, 85%, 53%, 1)',
            },
        }}
    >
        <Routes>
            <Route path={locations.index} element={<MainPage />} />
            <Route path={locations.main} element={<MainPage />} />
            <Route path={locations.okr} element={<OkrTreePage />} />
            <Route path={locations.okrList} element={<OkrListPage />} />
            <Route path={locations.tree} element={<OkrTreePage />} />
            <Route path={locations.list} element={<OkrListPage />} />
            <Route path={locations.objective} element={<ObjectivePage />} />
            <Route path={locations.objectives} element={<ObjectivesPage />} />
            <Route path={locations.createOKR} element={<CreateOKRPage />} />
            <Route path={locations.createWBS} element={<CreateWBS />} />
            <Route path={locations.wbs} element={<WbsTreePage />} />
            <Route path={locations.wbsTree} element={<WbsTreePage />} />
            <Route path={locations.wbsList} element={<WbsLisPage />} />
            <Route path={locations.wbsGanta} element={<WbsLisPage />} />
            <Route path={locations.createObjective} element={<CreateObjectivePage />} />
            <Route
                path={locations.createObjectiveWithoutParent}
                element={<CreateObjectivePage />}
            />
            <Route path={locations.editObjective} element={<EditObjectivePage />} />
            <Route path={locations.createKeyResult} element={<CreateKeyResultPage />} />
            <Route path={locations.editKeyResult} element={<EditKeyResultPage />} />
        </Routes>
    </ConfigProvider>
);
