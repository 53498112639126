import NiceModal from '@ebay/nice-modal-react';
import { ColumnType, ColumnGroupType } from 'antd/es/table';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import { IconButton } from '@mui/material';
import { Tooltip, Tag } from 'antd';

import { formatDate } from 'src/utils/format-date';
import { progressUnitsValues } from 'src/constants/progress-units-values';
import { Objective } from 'src/api/okr/objective/objective.types';
import { ObjectiveDrawer } from 'src/widgets/objective-drawer/objective-drawer';
import { getProgress, getProgressColor } from 'src/utils/get-progress';

import styles from './okr-list.module.css';
import { ColumnValues } from './sort-functions';

export type ColumnsType<T = unknown> = (ColumnType<T> | ColumnGroupType<T>)[];

export const columns: ColumnsType<Objective> = [
    {
        key: 'number',
        title: '№',
        dataIndex: 'number',
        width: '81px',
        render: (value, row) => (
            <div
                className={styles['row-number']}
                data-hierarchical={row.number?.length === 1 ? 0 : row.number?.charAt(2)}
            >
                {value}
                <div className={styles['row-color']} />
            </div>
        ),
    },
    {
        key: 'title',
        title: `${ColumnValues.Title}`,
        dataIndex: 'title',
    },
    {
        key: 'progressEnd',
        title: `Ед. измерения`,
        dataIndex: 'progressEndValue',
        render(_, record) {
            const progressUnit = progressUnitsValues.find(
                ({ id }) => id === record.progressUnit
            )?.shortName;

            return progressUnit;
        },
    },
    {
        key: 'progressFactValue',
        title: `${ColumnValues.Current}`,
        dataIndex: 'progressFactValue',
        align: 'center',
        render(text: string, record) {
            const progressUnit = progressUnitsValues.find(
                ({ id }) => id === record.progressUnit
            )?.shortName;
            return (
                <>
                    {text} {progressUnit}
                </>
            );
        },
    },
    {
        key: 'progressEndValue',
        title: `${ColumnValues.Planned}`,
        dataIndex: 'progressEndValue',
        align: 'center',
        render(text: string, record) {
            const progressUnit = progressUnitsValues.find(
                ({ id }) => id === record.progressUnit
            )?.shortName;
            return (
                <>
                    {text} {progressUnit}
                </>
            );
        },
    },
    {
        key: 'progressFactValue',
        title: `Процент выполнения`,
        align: 'center',
        dataIndex: 'progressFactValue',
        render(text: number, record) {
            const value = getProgress(
                record.progressStartValue,
                record.progressFactValue,
                record.progressEndValue
            );

            const color = getProgressColor(value);
            return <Tag color={color}>{value}%</Tag>;
        },
    },
    {
        key: 'progressQ3',
        title: 'III квартал',
        dataIndex: 'progressQ3',
        render(text: string) {
            return <span className={styles.metric}>{text}</span>;
        },
        children: [
            {
                key: 'progressEndValueQ3',
                title: 'План',
                dataIndex: 'progressEndValueQ3',
                align: 'center',
                // @ts-ignore
                onHeaderCell() {
                    return {
                        dataScope: 'progressEndValueQ3',
                    };
                },
                render(text: string) {
                    return <span className={styles.metric}>{text}</span>;
                },
            },
            {
                key: 'progressFactValueQ3',
                title: 'Факт',
                dataIndex: 'progressFactValueQ3',
                align: 'center',
                // @ts-ignore
                onHeaderCell() {
                    return {
                        dataScope: 'progressFactValueQ3',
                    };
                },
                render(text: string) {
                    return <span className={styles.metric}>{text}</span>;
                },
            },
            {
                key: 'progressFactValue',
                title: `План выполнения`,
                dataIndex: 'progressFactValueQ3',
                align: 'center',
                // @ts-ignore
                onHeaderCell() {
                    return {
                        dataScope: 'progressFactValueQ3',
                    };
                },
                render(text: string) {
                    return <span className={styles.metric}>{text}%</span>;
                },
            },
        ],
    },
    {
        key: 'progressQ4',
        title: 'IV квартал',
        dataIndex: 'progressQ4',
        className: 'group-3',
        align: 'center',
        render(text: string) {
            return <span className={styles.metric}>{text}%</span>;
        },
        children: [
            {
                key: 'progressEndValueQ4',
                title: 'План',
                dataIndex: 'progressEndValueQ4',
                align: 'center',
                // @ts-ignore
                onHeaderCell() {
                    return {
                        dataScope: 'progressEndValueQ3',
                    };
                },
                render(text: string) {
                    return <span className={styles.metric}>{text}</span>;
                },
            },
            {
                key: 'progressFactValueQ4',
                title: 'Факт',
                dataIndex: 'progressFactValueQ4',
                align: 'center',
                // @ts-ignore
                onHeaderCell() {
                    return {
                        dataScope: 'progressFactValueQ3',
                    };
                },
                render(text: string) {
                    return <span className={styles.metric}>{text}</span>;
                },
            },
            {
                key: 'progressFact',
                title: `План выполнения`,
                dataIndex: 'progressFactValueQ4',
                align: 'center',
                // @ts-ignore
                onHeaderCell() {
                    return {
                        dataScope: 'progressFactValue',
                    };
                },
                render(text: string) {
                    return <span className={styles.metric}>{text}%</span>;
                },
            },
        ],
    },
    {
        key: 'dateDeadline',
        title: `${ColumnValues.Deadline}`,
        dataIndex: 'dateDeadline',
        render: (text: string) => <>{formatDate(text, 'dd.MM.yy')}</>,
    },
    {
        key: 'assignedUsers',
        title: `${ColumnValues.Assignee}`,
        width: '196px',
        dataIndex: 'assignedUsers',
        render(users: Array<Record<string, unknown>>, node) {
            // eslint-disable-next-line @typescript-eslint/no-shadow, @typescript-eslint/no-explicit-any
            const showModal = (node: any) =>
                NiceModal.show(ObjectiveDrawer, {
                    objective: node,
                    title: node.number ? `Objective ${node.number}` : 'OKR',
                });

            const isKeyResult = 'type' in node;
            return (
                <div className={styles.users}>
                    <div>
                        {users
                            ?.filter((person) => person !== null)
                            .map(({ personName }) => personName)
                            ?.join(', ')}
                    </div>
                    {!isKeyResult && (
                        <div className={styles['buttons-container']}>
                            <Tooltip title="Редактировать" arrow>
                                <IconButton
                                    className={styles['button-icon']}
                                    onClick={() => showModal(node)}
                                >
                                    <BorderColorIcon aria-label="Редактировать" />
                                </IconButton>
                            </Tooltip>
                        </div>
                    )}
                </div>
            );
        },
    },
];
