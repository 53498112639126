import NiceModal from '@ebay/nice-modal-react';
import { ColumnType, ColumnGroupType } from 'antd/es/table';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import { IconButton } from '@mui/material';
import { Tooltip, Tag } from 'antd';

import { formatDate } from 'src/utils/format-date';
import { Objective } from 'src/api/okr/objective/objective.types';
import { ObjectiveDrawer } from 'src/widgets/objective-drawer/objective-drawer';
import { getProgress, getProgressColor } from 'src/utils/get-progress';
import { progressUnitsValues } from 'src/constants/progress-units-values';

import styles from './okr-list.module.css';
import { ColumnValues } from './sort-functions';

export type ColumnsType<T = unknown> = (ColumnType<T> | ColumnGroupType<T>)[];

function isMonthAndYearBetweenDates(
    dateStart: string,
    dateDeadline: string,
    monthIndex: number,
    year: number
): boolean {
    const startDate = new Date(dateStart);
    const deadlineDate = new Date(dateDeadline);

    const targetDate = new Date(year, monthIndex, 1);

    return targetDate >= startDate && targetDate <= deadlineDate;
}

export const gantaColumns: ColumnsType<Objective> = [
    {
        key: 'number',
        title: '№',
        dataIndex: 'number',
        width: '81px',
        render: (value, row) => (
            <div
                className={styles['row-number']}
                data-hierarchical={row.number?.length === 1 ? 0 : row.number?.charAt(2)}
            >
                {value}
                <div className={styles['row-color']} />
            </div>
        ),
    },
    {
        key: 'title',
        title: `${ColumnValues.Title}`,
        dataIndex: 'title',
    },
    // {
    //     key: 'progressEnd',
    //     title: `Ед. измерения`,
    //     dataIndex: 'progressEndValue',
    //     render(_, record) {
    //         const progressUnit = progressUnitsValues.find(
    //             ({ id }) => id === record.progressUnit
    //         )?.shortName;
    //
    //         return progressUnit;
    //     },
    // },
    {
        key: 'progressFactValue',
        title: `${ColumnValues.Current}`,
        dataIndex: 'progressFactValue',
        align: 'center',
        render(text: string, record) {
            const progressUnit = progressUnitsValues.find(
                ({ id }) => id === record.progressUnit
            )?.shortName;
            return (
                <>
                    {text} {progressUnit}
                </>
            );
        },
    },
    {
        key: 'progressFactValue',
        title: `Процент выполнения`,
        align: 'center',
        dataIndex: 'progressFactValue',
        render(text: number, record) {
            const color = getProgressColor(text);
            const value = getProgress(
                record.progressStartValue,
                record.progressFactValue,
                record.progressEndValue
            );

            return <Tag color={color}>{value}%</Tag>;
        },
    },
    {
        key: 'dateDeadline',
        title: `${ColumnValues.Deadline}`,
        dataIndex: 'dateDeadline',
        render: (text: string) => <>{formatDate(text, 'dd.MM.yy')}</>,
    },
    {
        key: 'assignedUsers',
        title: `${ColumnValues.Assignee}`,
        width: '196px',
        dataIndex: 'assignedUsers',
        render(users: Array<Record<string, unknown>>, node: unknown) {
            // eslint-disable-next-line @typescript-eslint/no-shadow, @typescript-eslint/no-explicit-any
            const showModal = (node: any) =>
                NiceModal.show(ObjectiveDrawer, {
                    objective: node,
                    title: node.number ? `Objective ${node.number}` : 'OKR',
                });

            return (
                <div className={styles.users}>
                    <div>
                        {users
                            ?.filter((person) => person !== null)
                            .map(({ personName }) => personName)
                            ?.join(', ')}
                    </div>
                    <div className={styles['buttons-container']}>
                        <Tooltip title="Редактировать" arrow>
                            <IconButton
                                className={styles['button-icon']}
                                onClick={() => showModal(node)}
                            >
                                <BorderColorIcon aria-label="Редактировать" />
                            </IconButton>
                        </Tooltip>
                    </div>
                </div>
            );
        },
    },
    {
        key: 'progressQ3',
        title: '2024',
        dataIndex: 'progressQ3',
        render(text: string) {
            return <span className={styles.metric}>{text}</span>;
        },
        children: [
            {
                key: 'progressQ3',
                title: 'III квартал',
                dataIndex: 'progressQ3',
                align: 'center',
                // @ts-ignore
                onHeaderCell() {
                    return {
                        dataScope: 'progressQ3',
                    };
                },
                render(text: string) {
                    return <span className={styles.metric}>{text}</span>;
                },
                children: [
                    {
                        key: 'progressQ3Jul',
                        dataIndex: 'progressQ3Jul',
                        title: () => <span className={styles['column-title']}>Июль</span>,
                        className: styles['no-padding-column'],
                        render(_, record) {
                            const isComplete = isMonthAndYearBetweenDates(
                                record.dateStart,
                                record.dateDeadline,
                                6,
                                2024
                            );

                            return isComplete ? <div /> : <span />;
                        },
                    },
                    {
                        key: 'progressQ3August',
                        dataIndex: 'progressQ3August',
                        title: () => <span className={styles['column-title']}>Август</span>,
                        className: styles['no-padding-column'],
                        render(_, record) {
                            const isComplete = isMonthAndYearBetweenDates(
                                record.dateStart,
                                record.dateDeadline,
                                7,
                                2024
                            );

                            return isComplete ? <div /> : <span />;
                        },
                    },
                    {
                        key: 'progressQ3September',
                        dataIndex: 'progressQ3September',
                        title: () => <span className={styles['column-title']}>Сентябрь</span>,
                        className: styles['no-padding-column'],
                        render(_, record) {
                            const isComplete = isMonthAndYearBetweenDates(
                                record.dateStart,
                                record.dateDeadline,
                                8,
                                2024
                            );

                            return isComplete ? <div /> : <span />;
                        },
                    },
                ],
            },
            {
                key: 'progressQ4',
                title: 'IV квартал',
                dataIndex: 'progressQ4',
                align: 'center',
                // @ts-ignore
                onHeaderCell() {
                    return {
                        dataScope: 'progressQ4',
                    };
                },
                render(text: string) {
                    return <span className={styles.metric}>{text}</span>;
                },
                children: [
                    {
                        key: 'progressQ3October',
                        dataIndex: 'progressQ3October',
                        title: () => <span className={styles['column-title']}>Октябрь</span>,
                        className: styles['no-padding-column'],
                        render(_, record) {
                            const isComplete = isMonthAndYearBetweenDates(
                                record.dateStart,
                                record.dateDeadline,
                                9,
                                2024
                            );

                            return isComplete ? <div /> : <span />;
                        },
                    },
                    {
                        key: 'progressQ3November',
                        dataIndex: 'progressQ3November',
                        title: () => <span className={styles['column-title']}>Ноябрь</span>,
                        className: styles['no-padding-column'],
                        render(_, record) {
                            const isComplete = isMonthAndYearBetweenDates(
                                record.dateStart,
                                record.dateDeadline,
                                10,
                                2024
                            );

                            return isComplete ? <div /> : <span />;
                        },
                    },
                    {
                        key: 'progressQ3December',
                        dataIndex: 'progressQ3December',
                        title: () => <span className={styles['column-title']}>Декабрь</span>,
                        className: styles['no-padding-column'],
                        render(_, record) {
                            const isComplete = isMonthAndYearBetweenDates(
                                record.dateStart,
                                record.dateDeadline,
                                11,
                                2024
                            );

                            return isComplete ? <div /> : <span />;
                        },
                    },
                ],
            },
        ],
    },
    {
        key: 'progressQ4',
        title: '2025',
        dataIndex: 'progressQ4',
        render(text: string) {
            return <span className={styles.metric}>{text}</span>;
        },
        children: [
            {
                key: 'progressQ1',
                title: 'I квартал',
                dataIndex: 'progressQ1',
                align: 'center',
                // @ts-ignore
                onHeaderCell() {
                    return {
                        dataScope: 'progressQ4',
                    };
                },
                render(text: string) {
                    return <span className={styles.metric}>{text}</span>;
                },
                children: [
                    {
                        key: 'progressQ1January',
                        dataIndex: 'progressQ3January',
                        title: () => <span className={styles['column-title']}>Январь</span>,
                        className: styles['no-padding-column'],
                        render(_, record) {
                            const isComplete = isMonthAndYearBetweenDates(
                                record.dateStart,
                                record.dateDeadline,
                                0,
                                2025
                            );

                            return isComplete ? <div /> : <span />;
                        },
                    },
                    {
                        key: 'progressQ1February',
                        dataIndex: 'progressQ1February',
                        title: () => <span className={styles['column-title']}>Февраль</span>,
                        className: styles['no-padding-column'],
                        render(_, record) {
                            const isComplete = isMonthAndYearBetweenDates(
                                record.dateStart,
                                record.dateDeadline,
                                1,
                                2025
                            );

                            return isComplete ? <div /> : <span />;
                        },
                    },
                    {
                        key: 'progressQ1Mart',
                        dataIndex: 'progressQ1Mart',
                        title: () => <span className={styles['column-title']}>Март</span>,
                        className: styles['no-padding-column'],
                        render(_, record) {
                            const isComplete = isMonthAndYearBetweenDates(
                                record.dateStart,
                                record.dateDeadline,
                                2,
                                2025
                            );

                            return isComplete ? <div /> : <span />;
                        },
                    },
                ],
            },
            {
                key: 'progressQ2',
                title: 'II квартал',
                dataIndex: 'progressQ2',
                align: 'center',
                // @ts-ignore
                onHeaderCell() {
                    return {
                        dataScope: 'progressQ4',
                    };
                },
                render(text: string) {
                    return <span className={styles.metric}>{text}</span>;
                },
                children: [
                    {
                        key: 'progressQ3April',
                        dataIndex: 'progressQ3April',
                        title: () => <span className={styles['column-title']}>Апрель</span>,
                        className: styles['no-padding-column'],
                        render(_, record) {
                            const isComplete = isMonthAndYearBetweenDates(
                                record.dateStart,
                                record.dateDeadline,
                                3,
                                2025
                            );

                            return isComplete ? <div /> : <span />;
                        },
                    },
                    {
                        key: 'progressQ3May',
                        dataIndex: 'progressQ3May',
                        title: () => <span className={styles['column-title']}>Май</span>,
                        className: styles['no-padding-column'],
                        render(_, record) {
                            const isComplete = isMonthAndYearBetweenDates(
                                record.dateStart,
                                record.dateDeadline,
                                4,
                                2025
                            );

                            return isComplete ? <div /> : <span />;
                        },
                    },
                    {
                        key: 'progressQ3Jun',
                        dataIndex: 'progressQ3Jun',
                        title: () => <span className={styles['column-title']}>Июнь</span>,
                        className: styles['no-padding-column'],
                        render(_, record) {
                            const isComplete = isMonthAndYearBetweenDates(
                                record.dateStart,
                                record.dateDeadline,
                                5,
                                2025
                            );

                            return isComplete ? <div /> : <span />;
                        },
                    },
                ],
            },
        ],
    },
];
