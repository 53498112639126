import { FC } from 'react';
import { Link } from 'react-router-dom';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';

import styles from './objective-breadcrumbs.module.css';

interface Breadcrumb {
    id: string;
    label: string;
    link: string;
}

interface Props {
    data: Breadcrumb[];
}

export const ObjectiveBreadcrumbs: FC<Props> = ({ data }) => (
    <Breadcrumbs
        className={styles.container}
        separator={<PlayArrowRoundedIcon fontSize="small" />}
        aria-label="breadcrumb"
    >
        {data.map(({ id, label, link }) => (
            <Link key={id} className={styles.link} to={link} state={window.history.state.usr}>
                {label}
            </Link>
        ))}
    </Breadcrumbs>
);
