import { FC } from 'react';
import { Control, useWatch } from 'react-hook-form';

import { ProgressBar } from 'src/components/progress-bar/progress-bar';
import { TaskForm } from 'src/types/task-form';
import { getProgress } from 'src/utils/get-progress';

interface Props {
    control: Control<TaskForm>;
}

export const ObjectiveFormProgress: FC<Props> = ({ control }) => {
    const start = useWatch({ control, name: 'progressStartValue' });
    const fact = useWatch({ control, name: 'progressFactValue' });
    const plan = useWatch({ control, name: 'progressEndValue' });

    return <ProgressBar progress={getProgress(start, fact, plan)} />;
};
